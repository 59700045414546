// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/intlPlaneTicket',
  component: Start,
  children: [
    {
      path: 'inforList',
      name: '国际机票航班列表',
      redirect: '/home',
    },
    {
      path: 'bookDetails',
      redirect: '/home',
    },
    {
      // 国际机票订单确认
      name: 'intlPlaneTicketOrderConfirm',
      path: 'orderConfirm',
      redirect: '/home',
    },
    {
      // 国际机票订单列表
      name: 'intlPlaneTicketOrderList',
      path: 'orderList',
      redirect: '/home',
    },
    {
      path: 'orderDetails',
      name: '国际机票订单详情',
      component: resolve =>
        require(['pages/intl-plane-ticket/order/order-details/order-details.vue'], resolve)
    },
    {
      path: 'toRefund',
      name: '国际机票机票去退票',
      redirect: '/home',
    },
    {
      path: 'refundConfirm',
      name: '国际机票确认退票',
      redirect: '/home',
    },
    {
      path: 'refundDetails',
      name: '国际机票退票详情',
      component: resolve =>
        require(['pages/intl-plane-ticket/refund/refund-details/refund-details.vue'], resolve)
    },
    {
      // 国内机票改期
      name: 'interPlaneTicketToReschedule',
      path: 'toReschedule',
      redirect: '/home',
    },
    {
      // 国际机票询价单
      name: 'interPlaneTicketInquirySheet',
      path: 'inquirySheet',
      component: resolve => require(['pages/intl-plane-ticket/inquiry-sheet/apply.vue'], resolve)
    },
    {
      // 国际机票询价单列表
      name: 'intlPlaneTicketInquirySheetOrderList',
      path: 'inquirySheet/orderList',
      component: resolve => require(['pages/intl-plane-ticket/inquiry-sheet/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inquirySheet/orderDetails',
      name: '国际机票询价单详情',
      component: resolve => require(['pages/intl-plane-ticket/inquiry-sheet/order-details/order-details.vue'], resolve)
    },
    {
      path: 'inquirySheet/bookDetails',
      name: '国际机票询价单方案填写页',
      component: resolve => require(['pages/intl-plane-ticket/inquiry-sheet/book-details/book-details.vue'], resolve)
    },
    {
      path: 'inquirySheet/orderConfirm',
      name: '国际机票询价单方案确认页',
      component: resolve => require(['pages/intl-plane-ticket/inquiry-sheet/order-confirm/order-confirm.vue'], resolve)
    }
  ]
}
