export const VERSION = "7.15.2";

export const FLIGHT_PRICE_CHANGE = 316;
export const FLIGHT_AGREE_WHITE_TIPS_CODE = 318;
// 默认币种信息
export const DEFAULT_CURRENCY_INFO = {
  Currency: '人民币',
  CurrencyCode: 'CNY',
  CurrencySymbol: '￥',
  CurrencyType: 0
}

/** 酒店凌晨入住时间限制 */
export const YESTERDAY_CLOSE_HOURS = 6

export const INTER_YESTERDAY_CLOSE_HOURS = 0

export const HOTEL_FILTER_V = 'HOTEL_FILTER_V'
export const INTL_HOTEL_FILTER_V = 'INTL_HOTEL_FILTER_V'

export const AES_SECRET_KEY = '8F15B073-646C-47'

export const PLANE_ORDER = 1
export const HOTEL_ORDER = 2
export const INTL_PLANE_ORDER = 6
export const REFUND_INTL_PLANE_ORDER = 16
export const INTL_HOTEL_ORDER = 11
export const TRAIN_ORDER = 10
export const REFUND_PLANE_ORDER = 12
export const CHANGE_PLANE_ORDER = 13
export const APPLY_FORM = 17
export const BUS_ORDER = 18

export const APPID = '1181'

export const PROJECT = 'h5小程序'
