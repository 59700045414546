import Vue from "vue";
import Router from "vue-router";
import store from "../store";
// import RouterPath4Report from './routers/report'
import RouterPath4Announcement from "./routers/announcement";
import RouterPath4Base from "./routers/base";
import RouterPath4Business from "./routers/business";
import RouterPath4Personal from "./routers/personal";
import RouterPath4Hotel from "./routers/hotel";
import RouterPath4IntlHotel from "./routers/inter-hotel";
import RouterPath4IntlPlaneTicket from "./routers/intlPlaneTicket";
import RouterPath4PlaneTicket from "./routers/planeTicket";
import RouterPath4Train from "./routers/train";
import RouterPath4Car from "./routers/car";
import RouterPath4Dinner from "./routers/dinner";
import RouterPath4TravelApply from "./routers/travelApply";
import RouterPath4Bus from "./routers/bus";
import RouterPath4Trip from "./routers/trip";
import { getLanguage } from "../common/utility/utils";
import { DEFAULT_CURRENCY_INFO } from "../common/constants/common";
/**
 * Navigation cancelled from '/home' to '/travel' with a new navigation
 * 解决编程式路由往同一地址跳转时会报错的情况
 * 参考: https://blog.csdn.net/ZYS10000/article/details/121876297
 */
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
Vue.use(Router);
// 承載router-view的components
const Start = (resolve) => {
  import("components/start/start").then((module) => {
    resolve(module);
  });
};
const router = new Router({
  mode: "hash",
  routes: [
    RouterPath4Announcement,
    RouterPath4Base,
    RouterPath4Business,
    RouterPath4Personal,
    RouterPath4Hotel,
    RouterPath4IntlHotel,
    RouterPath4IntlPlaneTicket,
    RouterPath4PlaneTicket,
    RouterPath4Train,
    RouterPath4Car,
    RouterPath4Dinner,
    RouterPath4TravelApply,
    RouterPath4Bus,
    RouterPath4Trip,

    {
      path: "/callback",
      name: "自动登录回调",
      component: (resolve) => require(["pages/login/callback.vue"], resolve),
    },

    {
      path: "/silentLogin",
      name: "静默登录回调",
      component: (resolve) => require(["pages/login/silentLogin.vue"], resolve),
    },

    {
      path: "/callback4Oa",
      name: "OA授权登录",
      component: (resolve) => require(["pages/login/callback4Oa.vue"], resolve),
    },
    {
      path: "/personal/resetPassword",
      name: "修改密码",
      component: (resolve) =>
        require(["pages/personal/reset-password/reset-password.vue"], resolve),
    },
    {
      path: "/error",
      name: "Error页面",
      component: (resolve) => require(["pages/login/error.vue"], resolve),
    },

    {
      path: "*",
      name: "404页面",
      component: (resolve) => require(["pages/login/error.vue"], resolve),
    },

    {
      path: "/register",
      name: "注册",
      component: (resolve) =>
        require(["pages/login/register/register.vue"], resolve),
    },

    /** 审批 */
    {
      path: "/message",
      component: Start,
      children: [
        {
          path: "examine",
          name: "待我审批&为我审批",
          component: (resolve) =>
            require(["pages/message/examine/examine.vue"], resolve),
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "myExamineTemplates",
          name: "我的审批模版",
          component: (resolve) =>
            require(["pages/message/examine/myExamineTemplate.vue"], resolve),
        },
        {
          path: "center",
          name: "消息中心",
          component: (resolve) =>
            require(["pages/message/center/center.vue"], resolve),
        },
        {
          path: "orderNotice",
          name: "订单通知",
          component: (resolve) =>
            require(["pages/message/order-notice/order-notice.vue"], resolve),
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "flightNotice",
          name: "航班动态",
          component: (resolve) =>
            require(["pages/message/flight-notice/flight-notice.vue"], resolve),
          meta: {
            keepAlive: true,
          },
        },
      ],
    },

    /** 积分 */
    // {
    //   path: '/integral',
    //   redirect: '/integral/center',
    //   component: Start,
    //   children: [
    //     {
    //       path: 'center',
    //       name: '积分',
    //       component: resolve =>
    //         require(['pages/integral/center/center.vue'], resolve)
    //     }
    //   ]
    // },

    /** 预付款 */
    {
      path: "/advancePayment",
      redirect: "/advancePayment/center",
      component: Start,
      children: [
        {
          path: "center",
          name: "预付款",
          component: (resolve) =>
            require(["pages/advance-payment/center/center.vue"], resolve),
          meta: {
            keepAlive: true,
          },
        },
      ],
    },

    /** 旅行豆 */
    {
      path: "/travelBean",
      redirect: "/travelBean/center",
      component: Start,
      children: [
        {
          path: "center",
          name: "旅行豆",
          component: (resolve) =>
            require(["pages/travel-bean/center/center.vue"], resolve),
          meta: {
            keepAlive: true,
          },
        },
      ],
    },

    /** 支付 */
    {
      path: "/pay",
      component: Start,
      children: [
        {
          path: "callback",
          name: "支付回调",
          component: (resolve) =>
            require(["pages/pay/callback/callback.vue"], resolve),
        },
      ],
    },
  ],
});
const history = window.sessionStorage;
getLanguage();
let currency = window.localStorage.currency || 0; // 币种缓存
let CurrencyInfo =
  window.localStorage.CurrencyInfo || JSON.stringify(DEFAULT_CURRENCY_INFO); // 币种缓存
let language = window.localStorage.language || "CN";
let FollowTheSystemLanguage = window.localStorage.FollowTheSystemLanguage;
history.clear();
// 语言重新赋值
window.localStorage.language = language;
if (FollowTheSystemLanguage) {
  window.localStorage.FollowTheSystemLanguage = FollowTheSystemLanguage;
}

window.localStorage.currency = currency; // 添加本地币种缓存
window.localStorage.CurrencyInfo = CurrencyInfo; // 添加本地币种缓存

let historyCount = history.getItem("count") * 1 || 0;
history.setItem("/", 0);

// 定时刷新页面
const reloadDelayTime = 60000 * 30;
var reloadTimeout = setTimeout(reload, reloadDelayTime);
function reload() {
  window.location.reload();
}
router.beforeEach(function (to, from, next) {
  // 定时刷新页面
  reloadTimeout && clearTimeout(reloadTimeout);
  reloadTimeout = setTimeout(reload, reloadDelayTime);
  // ===================================================
  Vue.prototype.$loading.reset();
  // 判断使用那个跳转动画的逻辑
  const toIndex = history.getItem(to.path);
  const fromIndex = history.getItem(from.path);
  if (toIndex) {
    if (
      !fromIndex ||
      parseInt(toIndex, 10) > parseInt(fromIndex, 10) ||
      (toIndex === "0" && fromIndex === "0")
    ) {
      store.commit("UPDATE_DIRECTION", { direction: "forward" });
    } else {
      store.commit("UPDATE_DIRECTION", { direction: "reverse" });
    }
  } else {
    ++historyCount;
    history.setItem("count", historyCount);
    to.path !== "/" && history.setItem(to.path, historyCount);
    store.commit("UPDATE_DIRECTION", { direction: "forward" });
  }
  // =============================================================================================================
  history.setItem("previousPath", from.path);
  // 记录一个仿原生端的路由数组
  let previousPathList = !Vue.prototype.noValue(
    history.getItem("previousPathList")
  )
    ? JSON.parse(history.getItem("previousPathList"))
    : [];

  // 兼容浏览器回退页面 报错 情况
  previousPathList = Array.isArray(previousPathList) ? previousPathList : [];

  let indexToPath = previousPathList.indexOf(to.path);
  if (indexToPath >= 0) {
    previousPathList = previousPathList.slice(0, indexToPath + 1);
  } else {
    previousPathList.push(to.path);
  }
  // console.log(previousPathList)
  history.setItem("previousPathList", JSON.stringify(previousPathList));
  // =====================================================================
  if (to.path === "/home") {
    // 清空图片缓存
    window.localStorage.submitAttachFiles = null;
    window.localStorage.orderSuccessInfo = null;
  }

  /** 此处的next()在构建项目框架时容易被遗漏, 造成无警告信息的显示问题. 切记不可遗漏! */
  next();
});
export default router;
