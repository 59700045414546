// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/planeTicket',
  component: Start,
  redirect: '/home',
  children: [
    {
      path: 'orderDetails',
      name: '国内机票订单详情',
      component: resolve => require(['pages/plane-ticket/order/order-details/order-details.vue'], resolve),
    },
    {
      // 国内酒店订单列表
      path: '*',
      redirect: '/home',
    },
  ]
}
