import {
  postFile,
  commonRequest
} from './config'
import {languageTypeInit} from 'common/utility/utils'
import axios from 'axios'
import Vue from 'vue'
import router from '../router'
import {FLIGHT_PRICE_CHANGE, FLIGHT_AGREE_WHITE_TIPS_CODE, VERSION} from '../common/constants/common'
import {clearToken, getIsApp, personalRoutePush, sendMessageToApp, setHomsomLoginType} from '../common/utility/utils';
import store from "@/store";
import dayjs from "dayjs";
const ERROR_SERVICE = 999
const AUTH_FAILED = 101
const AUTH_OVERDUE = 102
const VERSION_OVERDE = 107
const FLIGHT_OVERDUE = 305
const INTLFLIGHT_OVERDUE = 1110
const TRAIN_CHANGE_FAIL = 1011
const HOTEL_OVERDUE = 503
const TRAIN_OVERDUE = 1004
const CAR_OVERDUE = 9995
const ERROR_SERVICE_WARNING = 99996
const ERROR_LOGIN_SWITCH_UNIT = 2500 // 个人切换到单位
const ERROR_LOGIN_SWITCH_MEMBER = 2501 // 单位切换到个人
const CancelToken = axios.CancelToken
let cancelList = []
// axios 设置headers
let RequestSource = 'Web' // Wechat: 微信 WechatMiniProgram: 小程序 Web: 网页 Android: 安卓 Apple: ios
let ua = window.navigator.userAgent.toLowerCase() // 获取判断用的对象
if (window.__wxjs_environment === 'miniprogram') {
  // RequestSource = 'WechatMiniProgram'
  RequestSource = 'Wechat'
} else if (/micromessenger/.test(ua)) {
  RequestSource = 'Wechat'
}
axios.interceptors.request.use(
  config => {
    config.headers.RequestSource = encodeURI(RequestSource)
    config.headers.SoftwareVersion = encodeURI(VERSION)
    config.headers.DeviceInformation = encodeURI(ua)
    config.headers.LanguageType = languageTypeInit(window.localStorage.language)
    config.headers.Token = encodeURI(window.localStorage.HomsomFAFKLDIGLDA ? window.localStorage.HomsomFAFKLDIGLDA : '')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 关闭弹框修正 loadNode.show 计数
function loadClose(index) {
  if (cancelList[index] && cancelList[index].hasLoading) {
    console.log('存在loading，需要执行关闭事件', cancelList[index].url)
    Vue.prototype.$loading.close()
  }
}
// ======================================================================
// POST/GET/PUT/DELETE 请求 notCancel 接口并发的话不取消
export function Request(url, method, params, data, alwaysPass = false, hiddenLoading = false, notCancel = false) {
  let index = cancelList.map(v => v.url).indexOf(url)
  if (index >= 0 && !notCancel) {
    typeof cancelList[index].cancel === 'function' && cancelList[index].cancel()
    // loadClose(index)
    cancelList.splice(index, 1)
  }
  (!alwaysPass && !hiddenLoading) && Vue.prototype.$loading()
  return new Promise(function(resolve, reject) {
    // 取消重复请求
    let cancelToken = new CancelToken(c => {
      cancelList.unshift({
        hasLoading: !alwaysPass && !hiddenLoading,
        url: url,
        cancel: c
      })
    })
    // =============================================================
    axios(commonRequest(url, method, params, data, cancelToken))
      .then(res => {
        // 请求成功后移除cancelList暂存的cancel方法
        let index = cancelList.map(v => v.url).indexOf(url)
        loadClose(index)
        if (index >= 0) {
          cancelList.splice(index, 1)
        }
        // =======================================================
        if (res.data.IsSuccess || alwaysPass) {
          // 币种校验
          if (res.data.CurrencyInfo) {
            if (
              (window.localStorage.currency || window.localStorage.currency === 0) &&
              res.data.CurrencyInfo.CurrencyType !== Number(window.localStorage.currency)
            ) {
              router.replace({path: '/error', query: {tips: escape(Vue.prototype.$t('CurrencySelectedIncorrectly'))}})
            } else {
              Vue.prototype.CurrencyInfo = res.data.CurrencyInfo
              Vue.prototype.CurrencySymbol = res.data.CurrencyInfo.CurrencySymbol
            }
          }
          resolve(res.data)
        } else {
          // 登录过期/失效
          if (
            res.data.ErrorCode === AUTH_FAILED ||
            res.data.ErrorCode === AUTH_OVERDUE
          ) {
            let localStorage = window.localStorage
            if (router.currentRoute.path !== '/callback') {
              localStorage.authCallbackFullPath = router.currentRoute.fullPath
              localStorage.authCallbackPath = router.currentRoute.path
            }
            clearToken()
            let decodeRes = decodeURIComponent(res.data.RedirectUrl)
            // console.log('decodeRes: ', decodeRes)
            if (getIsApp()) { // app内打开 登录失效 调用app方法
              sendMessageToApp()
            } else {
              // 详细备注 wap.homsom.com/README.md RemarksCode 202411290001
              let HomsomLoginType = window.localStorage.HomsomLoginType
              if (HomsomLoginType && Number(HomsomLoginType) === 2) {
                personalRoutePush({
                  path: '/home',
                }, 'replace')
              } else {
                window.location.href = decodeRes
              }
            }
          // 版本过期
          } else if (res.data.ErrorCode === VERSION_OVERDE) {
            Vue.prototype.$alert({
              title: Vue.prototype.$t('Tips'),
              content: Vue.prototype.$t('TheNewVersionIsOnlinePleaseUpdateToTheLatestVersion'),
              confirmTxt: Vue.prototype.$t('ConfirmUpdate'),
              confirmFn: () => {
                Vue.prototype.$loading(Vue.prototype.$t('Upgrading'))
                setTimeout(() => {
                  let language = window.localStorage.language || 'CN'
                  let FollowTheSystemLanguage = window.localStorage.FollowTheSystemLanguage
                  let HomsomFAFKLDIGLDA = window.localStorage.HomsomFAFKLDIGLDA

                  // 查询页 机票火车票行程历史 版本更新时保留
                  let HISTORY_TRIP_PLANE_CITY = window.localStorage.HISTORY_TRIP_PLANE_CITY
                  let HISTORY_TRIP_TRAIN_CITY = window.localStorage.HISTORY_TRIP_TRAIN_CITY

                  let HomsomLoginType = window.localStorage.HomsomLoginType

                  window.localStorage.clear()
                  // 语言重新赋值
                  window.localStorage.language = language
                  HISTORY_TRIP_PLANE_CITY && (window.localStorage.HISTORY_TRIP_PLANE_CITY = HISTORY_TRIP_PLANE_CITY)
                  HISTORY_TRIP_TRAIN_CITY && (window.localStorage.HISTORY_TRIP_TRAIN_CITY = HISTORY_TRIP_TRAIN_CITY)
                  if (FollowTheSystemLanguage) {
                    window.localStorage.FollowTheSystemLanguage = FollowTheSystemLanguage
                  }
                  window.localStorage.HomsomFAFKLDIGLDA = HomsomFAFKLDIGLDA

                  setHomsomLoginType(HomsomLoginType || '') // 详细备注 wap.homsom.com/README.md RemarksCode 202411290001
                  window.location.reload()
                }, 1000)
              }
            })

          // 航班信息失效
          } else if (res.data.ErrorCode === FLIGHT_OVERDUE) {
            reject(res)
          // 国际航班信息失效
          } else if (res.data.ErrorCode === FLIGHT_PRICE_CHANGE) {
            // 国内航班价格发生变化
            reject(res)
          } else if (url === 'flight/submitBooking' && res.data.ErrorCode === FLIGHT_AGREE_WHITE_TIPS_CODE) {
            // 国内机票保存 您的订单中的乘机人不在航司白名单，因此无法预订当前协议价产品
            reject(res)
          } else if (res.data.ErrorCode === INTLFLIGHT_OVERDUE) {
            reject(res)
          // 酒店信息失效
          } else if (res.data.ErrorCode === HOTEL_OVERDUE) {
            reject(res)
          // 火车票信息失效
          } else if (res.data.ErrorCode === TRAIN_OVERDUE) {
            reject(res)
          // 用车信息失效
          } else if (res.data.ErrorCode === CAR_OVERDUE) {
            reject(res)
          } else if (url === 'train/submitChange/v2' && res.data.ErrorCode === TRAIN_CHANGE_FAIL) { // 针对 火车票改签失败提示弹框
            reject(res)
          } else if (res.data.ErrorCode === ERROR_SERVICE) {
            // 系统报错
          } else if (res.data.ErrorCode === ERROR_SERVICE_WARNING) {
            router.replace({path: '/error', query: {tips: escape(res.data.ErrMsg)}})
            reject(res)
          } else if (res.data.ErrorCode === ERROR_LOGIN_SWITCH_UNIT || res.data.ErrorCode === ERROR_LOGIN_SWITCH_MEMBER) {
            let loginType = res.data.ErrorCode === ERROR_LOGIN_SWITCH_UNIT ? 2 : 1;
            /** 解决小程序 刷新、支付回跳 必现 中转页 问题 */
            if (router.currentRoute.path === '/home' || router.currentRoute.path === '/') {
              personalRoutePush({
                path: '/home',
              }, 'replace')
            } else {
              personalRoutePush({
                path: '/loginSwitch',
                query: {
                  loginType: loginType, // 1 单位 2 个人
                  tmsp: new Date().getTime(),
                }
              }, 'push', '/personal/airticket')
            }
          } else {
            Vue.prototype.$toast(res.data.ErrMsg)
            reject(res)
          }
        }
      })
      .catch(err => {
        let index = cancelList.map(v => v.url).indexOf(url)
        loadClose(index)
        if (axios.isCancel(err)) {
          console.log('Request canceled!')
        } else {
          if (!alwaysPass) {
            Vue.prototype.$toast(Vue.prototype.$t('TheSystemIsBusyPleaseTryAgainLater'))
          }
          reject(err)
        }
      })
  })
}
// POST文件
function POSTFILE (url, data, filePath = '', callbackFn) {
  // console.log('filePath = ', filePath)
  Vue.prototype.$loading()
  return new Promise(function (resolve, reject) {
    axios(Object.assign(postFile(url, data, filePath), {
      onUploadProgress: function (progressEvent) {
        if (progressEvent.lengthComputable) {
          callbackFn && callbackFn(progressEvent);
        }
      }})).then((res) => {
      Vue.prototype.$loading.close()
      resolve(res.data)
    }).catch((err) => {
      Vue.prototype.$loading.close()
      Vue.prototype.$toast(Vue.prototype.$t('TheSystemIsBusyPleaseTryAgainLater'))
      return reject(err)
    })
  })
}
//                   00000000000
//  00000 00 00      00000000000
//  00000  000                00
//     00  00  00     0000000000
//  00 0    0000      0000000000
//   0000000000               00
//  000 00000 0000 000000000000000
// 000         00  000000000000000
//  0 000000000      00   00  00
//    00     00       00  00 00
//    000000000         000000
//    000000000       000000000
//     00   00      0000  00 00000
//  0000000000000   00   000   00
//  0000000000000        00
/**
 * 登陆
 */
export function kpiLoginUserLogin(data = {}) {
  return Request('Login/UserLogin/v2', 'post', undefined, data)
}
/**
 * OA登录
 */
export function kpiLoginOaLogin(data = {}) {
  return Request(`login/oaLogin`, 'post', undefined, data)
}

/**
 * 忘记密码--校验当前账户是否存在
 */
export function kpiLoginCheckUserName(data = {}) {
  return Request(`Login/CheckUserName`, 'post', undefined, data)
}

/**
 * 手机号邮箱登录 获取验证码
 */
export function kpiLoginGetVerifyCode(data = {}) {
  return Request(`Login/GetVerifyCodeV2`, 'post', undefined, data)
}

/**
 * 获取手机验证码
 */
export function kpiLoginGetVerifyCodeWithPhone(data = {}) {
  return Request(`Login/GetPhoneVerifyCode`, 'post', undefined, data)
}

/**
 * 手机号验证码登录
 */
export function kpiSignInWithPhoneVerifyCode(data = {}) {
  return Request(`Login/SignInWithPhoneVerifyCode`, 'post', undefined, data)
}
/**
 * 登录 单位个人切换
 */
export function kpiLoginToggleApp(data = {}) {
  return Request(`Login/ToggleApp`, 'post', undefined, data)
}

/**
 * 手机号邮箱登录
 */
export function kpiLoginSignInWithVerifyCode(data = {}) {
  return Request(`Login/SignInWithVerifyCode`, 'post', undefined, data)
}
/**
 * 留资
 */
export function kpiIndexRegisterCompany(data = {}) {
  return Request(`personalapi/systeminfo/addRegisterCompany`, 'post', undefined, data)
}
/**
 * 留资 发送验证码
 */
export function kpiSendMobileVerificationCode(data = {}) {
  return Request(`mobileverification/sendMobileVerificationCode`, 'post', undefined, data)
}
/**
 * 获取授权登录链接
 */
export function kpiSiteAuthGetNewSiteUrl(data = {}) {
  return Request(`authapi/SiteAuth/GetNewSiteUrl`, 'post', undefined, data)
}
//     00   00      0000000000000
//     00   00      0000000000000
//      00 00             00
// 000000000000000        00
// 000000000000000    0000000000
//       00           0000000000
//    0000000000      00      00
//    0000000000      00 00   00
//    00      00      00 00   00
//    0000000000      00 00   00
//    00      00      00 00   00
//    0000000000         00 0
//    00      00        00  000
//    0000000000    00000     00
//    0000000000    000        00
/**
 * 首页用户信息获取接口
 */
export function kpiHomeGethomeinitinfo(data = {}) {
  return Request('home/gethomeinitinfo', 'post', undefined, data)
}
/**
 * 项目版本号
 */
export function kpiHomeGetversionnumber(data = {}) {
  return Request(`home/getversionnumber`, 'post', undefined, data)
}
/**
 * 验证活动资格
 */
export function kpiHomeCheckactivity(data = {}) {
  return Request(`home/checkactivity`, 'post', undefined, data, true)
}
/**
 * 领取红包
 */
export function kpiHomeReceiveredenvelope(data = {}) {
  return Request(`home/receiveredenvelope`, 'post', undefined, data, true)
}
//        00          00
//        00          00      00
//  00000000000000    00      00
//  00000000000000   00       00
//        00         00 0000000000
//        00        000 0000000000
//   00000000000   0000       00
//   00000000000   0 00  00   00
//     00    00      00   00  00
//     00   00       00   00  00
//      00 00        00       00
//       000         00       00
//     0000000       00       00
//  00000   000000   00     0000
//  000        000   00     000
/**
 * 微信支付
 */
export function kpiPayPayStart(data = {}) {
  return Request(`Pay/PayStart`, 'post', undefined, data)
}
/**
 * 小程序支付发起
 */
export function kpiPayOpenPayStart(data = {}) {
  return Request(`Pay/OpenPayStart`, 'post', undefined, data)
}
/**
 * 支付宝支付发起
 */
export function kpiPayAlipayAppStart(data = {}) {
  return Request(`Pay/AlipayAppStart`, 'post', undefined, data)
}
//   0000     00                    0
//  00  00    00                    0
//  0    0    00              0     0
//  0    0   0000             0     0
//  0    0   0  0   0    0  00000   0 000
//  0    0   0  0   0    0    0     00  00
//  0    0   0  0   0    0    0     00   0
//  0    0  000000  0    0    0     0    0
//  0    0  0    0  0   00    0     0    0
//  00  00  0    0  00  00    0  0  0    0
//   0000  00    00  000 0     000  0    0
/**
 * 传code给后台
 */
export function kpiWeChatOAuthCallBack(data = {}) {
  return Request('WeChatOAuth/CallBack', 'post', undefined, data)
}
/**
 * 获取小程序OpenId
 */
export function kpiWeChatOAuthWeChatOpenOAuth(data = {}) {
  return Request('WeChatOAuth/WeChatOpenOAuth', 'post', undefined, data)
}
/**
 * 微信config
 */
export function kpiWeChatOAuthWeChatJsConfigResponse(data = {}) {
  return Request(`WeChatOAuth/WeChatJsConfigResponse`, 'post', undefined, data)
}
/**
 * 获取申请页初始信息OA 旧版
 */
export function kpiOaflightGetOaFlightInitInfo(data = {}) {
  return Request('oaflight/getOaFlightInitInfo', 'post', undefined, data)
}
/**
 * 获取申请页初始信息OA机票新版
 */
export function kpiFlightGetAuthorizationInfo(data = {}) {
  return Request('flight/getAuthorizationInfo/v3', 'post', undefined, data)
}
/**
 * 获取申请页初始信息OA酒店新版
 */
export function kpiHotelGetHotelAuthorizationInfo(data = {}) {
  return Request('hotel/getHotelAuthorizationInfo', 'post', undefined, data)
}
/**
 * 获取申请页初始信息OA火车票新版
 */
export function kpiTrainGetTrainAuthorizationInfo(data = {}) {
  return Request('train/getTrainAuthorizationInfo/v3', 'post', undefined, data)
}
/**
 * 获取oa授权信息列表
 */
export function kpiOaOaAuthList(data = {}) {
  // return Request(`oaflight/oaAuthList`, 'post', undefined, data)
  return Request(`oa/oaAuthList`, 'post', undefined, data)
}
/**
 * 获取oa授权信息详情
 */
export function kpiOaOaAuthDetail(data = {}) {
  return Request(`oa/oaAuthDetail/v3`, 'post', undefined, data)
}
/**
 * 获取待处理授权码列表信息
 */
export function kpiHomeGetUnHandleAuthCodeInfos(data = {}) {
  return Request(`home/getUnHandleAuthCodeInfos/v3`, 'post', undefined, data)
}
//       00          00  00  00
//        00         00  00  00
// 000000000000000   00  00  00
// 000000000000000 00000 00  00
// 00     00    00 00000 00  00
//   00000000000     00  00  00 00
//   00000000000     00  00000000
//   00   00  00     000 0000000
//   00000000000   00000 00  00
//   00   00  00   0000  00  00
//   00000000000     00  00  00
//   00000000000     00  00  00
//   00   00  00     00  000 00 00
//        00        000  000 00000
//        00        00   00   000
/**
 * 审批订单处理
 */
export function kpiVettingVettingHandleOrder(data = {}) {
  return Request(`vetting/vettingHandleOrder`, 'post', undefined, data)
}
/**
 * 审批列表
 */
export function kpiVettingGetFlightVettingList(data = {}, hiddenLoading = false) {
  return Request(`vetting/getFlightVettingList`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 审批列表
 */
export function kpiVettingGetHotelVettingList(data = {}, hiddenLoading = false) {
  return Request(`vetting/getHotelVettingList`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 火车票审批列表
 */
export function kpiVettingGetTrainVettingList(data = {}, hiddenLoading = false) {
  return Request(`vetting/getTrainVettingList`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 国际酒店审批列表
 */
export function kpiVettingGetIntlHotelVettingList(data = {}, hiddenLoading = false) {
  return Request(`vetting/getIntlHotelVettingList`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 获取所有业务下的审批列表
 */
export function kpiVettingGet(data = {}) {
  return Request(`vetting/get`, 'post', undefined, data)
}
/**
 * 重发审批接口
 */
export function kpiVettingReSendVettingMessage(data = {}) {
  return Request(`vetting/reSendVettingMessage`, 'post', undefined, data)
}
/**
 * 获取退票审批信息
 */
export function kpiFlightGetFlightRefundApprovalInitInfo(data = {}) {
  return Request(`flight/getFlightRefundApprovalInitInfo`, 'post', undefined, data)
}

//       00          00  00  00
//        00         00  00  00     0000000 000000   000000000000
// 000000000000000   00  00  00     0000000 000000   00  0  0  00
// 000000000000000 00000 00  00      00 00      00   000000000000
// 00     00    00 00000 00  00     0000000     00        00
//   00000000000     00  00  00 00  0000000     00  00000000000000
//   00000000000     00  00000000   00 00 0 000000        00
//   00   00  00     000 0000000    00 00 0 000000    0000000000
//   00000000000   00000 00  00     000 000 00        00      00
//   00   00  00   0000  00  00     00    0 00        0000000000
//   00000000000     00  00  00     0000000 00        00      00
//   00000000000     00  00  00     00    0 00  00    0000000000
//   00   00  00     00  000 00 00  0000000 00  00    00      00
//        00        000  000 00000  0000000 000000 000000000000000
//        00        00   00   000   00    0  0000  000000000000000
/**
 * 获取审批初始化信息
 */
export function kpiVettingsettingGetVettingInitInfo(data = {}) {
  return Request(`vettingsetting/getVettingInitInfo`, 'post', undefined, data)
}
/**
 * 获取审批白名单人员
 */
export function kpiVettingsettingGetVettingWhiteListers(data = {}) {
  return Request(`vettingsetting/getVettingWhiteListers`, 'post', undefined, data)
}
/**
 * 获取审批白名单未设置人员
 */
export function kpiVettingsettingGetWhiteListUnBindUnitPersons(data = {}) {
  return Request(`vettingsetting/getWhiteListUnBindUnitPersons`, 'post', undefined, data)
}
/**
 * 添加审批白名单人员
 */
export function kpiVettingsettingAddVettingWhiteListers(data = {}) {
  return Request(`vettingsetting/addVettingWhiteListers`, 'post', undefined, data)
}
/**
 * 删除审批白名单人员
 */
export function kpiVettingsettingDeleteVettingWhiteListers(data = {}) {
  return Request(`vettingsetting/deleteVettingWhiteListers`, 'post', undefined, data)
}
/**
 * 删除指定审批人
 */
export function kpiVettingsettingDeleteAppointVettingPersons(data = {}) {
  return Request(`vettingsetting/deleteAppointVettingPersons`, 'post', undefined, data)
}
/**
 * 获取指定审批人列表
 */
export function kpiVettingsettingGetAppointVettingPersons(data = {}) {
  return Request(`vettingsetting/getAppointVettingPersons`, 'post', undefined, data)
}
/**
 * 获取指定审批人未设置人员列表
 */
export function kpiVettingsettingGetAppointVettingPersonUnBindUnitPersons(data = {}) {
  return Request(`vettingsetting/getAppointVettingPersonUnBindUnitPersons`, 'post', undefined, data)
}
/**
 * 添加指定审批人
 */
export function kpiVettingsettingAddAppointVettingPersons(data = {}) {
  return Request(`vettingsetting/addAppointVettingPersons`, 'post', undefined, data)
}
/**
 * 获取审批模板列表
 */
export function kpiVettingsettingGetVettingTemplates(data = {}) {
  return Request(`vettingsetting/getVettingTemplates`, 'post', undefined, data)
}
/**
 * 获取审批模板详情
 */
export function kpiVettingsettingGetVettingTemplateDetail(data = {}) {
  return Request(`vettingsetting/getVettingTemplateDetail`, 'post', undefined, data)
}
/**
 * 获取审批模板下绑定的员工
 */
export function kpiVettingsettingGetVettingTemplateBindUnitPersons(data = {}) {
  return Request(`vettingsetting/getVettingTemplateBindUnitPersons`, 'post', undefined, data)
}
/**
 * 获取审批模板下绑定的自定义项
 */
export function kpiVettingsettingGetVettingTemplateBindCustomItems(data = {}) {
  return Request(`vettingsetting/getVettingTemplateBindCustomItems`, 'post', undefined, data)
}
/**
 * 获取审批模板下没有绑定的员工
 */
export function kpiVettingsettingGetVettingTemplateUnBindUnitPersons(data = {}) {
  return Request(`vettingsetting/getVettingTemplateUnBindUnitPersons`, 'post', undefined, data)
}
/**
 * 获取审批模板下没有绑定的自定义项
 */
export function kpiVettingsettingGetVettingTemplateUnBindCustomItems(data = {}) {
  return Request(`vettingsetting/getVettingTemplateUnBindCustomItems`, 'post', undefined, data)
}
/**
 * 绑定员工到审批模板下
 */
export function kpiVettingsettingBindUnitPersonsToVettingTemplate(data = {}) {
  return Request(`vettingsetting/bindUnitPersonsToVettingTemplate`, 'post', undefined, data)
}
/**
 * 绑定自定义项到审批模板下
 */
export function kpiVettingsettingBindCustomItemsToVettingTemplate(data = {}) {
  return Request(`vettingsetting/bindCustomItemsToVettingTemplate`, 'post', undefined, data)
}
/**
 * 移除审批模板下的员工
 */
export function kpiVettingsettingUnBindUnitPersonsToVettingTemplate(data = {}) {
  return Request(`vettingsetting/unBindUnitPersonsToVettingTemplate`, 'post', undefined, data)
}
/**
 * 移除审批模板下的自定义项
 */
export function kpiVettingsettingUnBindCustomItemsToVettingTemplate(data = {}) {
  return Request(`vettingsetting/unBindCustomItemsToVettingTemplate`, 'post', undefined, data)
}

/**
 * 我的审批模版列表
 */
export function kpiVettingMyApproval(data = {}) {
  return Request(`vetting/myApproval`, 'post', undefined, data)
}

//  0000000000
//  0000000000     000000000000
//  00      00     000000000000
//  00      00     00
//  00      00     00   00
//  00      00     00   00
//  0000000000     00000000000
//  0000000000     00000000000
//  00      00     00   00  00
//  00      00     00   00  00
//  00      00     00  00   00
//  00      00     0   00   00
//  0000000000    00  00    00
//  0000000000    0  00   0000
//  00      00   00  0    000
/**
 * 获取日历相关信息
 */
export function kpiCalendarGet(data = {}) {
  return Request(`calendar/get`, 'post', undefined, data, false, true)
}
/**
 * 获取国内机票航线每日最低价信息
 */
export function kpiRouteLowestPriceGet(data = {}) {
  return Request(`routeLowestPrice/get`, 'post', undefined, data)
}
//                        00         00             0000000000000
//  0000000000000         00         00   000000    0000000000000
//  0000000000000    000000000000    00   000000        00 00
//  00         00    000000000000  000000 00  00     00000000000
//  00 0000000 00    00   00   00  000000 00  00     00 00 00 00
//  00 0000000 00    00   00   00    00   00  00     00000000000
//  00    00   00    00   00   00    00   00  00     00000000000
//  00 0000000 00    00  0000  00   0000  00  00
//  00    00   00    00 00  00 00   00000 00  00     00000000000
//  00    00 0 00    0000    0 00  0000 0 00  00
//  00 0000000000    000       00  0 00   00  00    0000000000000
//  00 0000000000    00        00    00   00  00    0000000000000
//  00         00    00        00    00  00   00 0    00  00 00
//  0000000000000    00      0000    00 000   0000   00  000  000
//  0000000000000    00       00     00  0     000  00   00     0
/**
 * 机票首页信息获取接口
 */
export function kpiFlightGetFlightIndexInitInfo(data = {}) {
  return Request('flight/getFlightIndexInitInfo', 'post', undefined, data)
}
/**
 * 模糊搜索机场和城市
 */
export function kpiFlightSearchAirportCity(data = {}) {
  return Request(`flight/searchAirportCity/v2`, 'post', undefined, data)
}
/**
 * 国内机票 校验是否可以查询机票
 */
export function kpiFlightCanSearch(data = {}) {
  return Request(`flight/canSearch `, 'post', undefined, data)
}
/**
 * 查询航班列表
 */
export function kpiFlightGetFlightList(data = {}) {
  return Request(`flight/getFlightList`, 'post', undefined, data, false, true)
}
/**
 * 筛选排序航班列表
 */
export function kpiFlightFiltFlights(data = {}) {
  return Request(`flight/filtFlights`, 'post', undefined, data)
}
/**
 * 获取舱位列表
 */
export function kpiFlightGetCabins(data = {}, hiddenLoading = false) {
  // return Request(`flight/getCabins`, 'post', undefined, data, false, hiddenLoading)
  return Request(`flight/getCabins/v3`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 获取退改签政策
 */
export function kpiFlightGetFlightCabinRule(data = {}) {
  return Request(`flight/getFlightCabinRule`, 'post', undefined, data)
}
/**
 * check是否还有余票 旧版
 */
export function kpiFlightCheckLeftSeat(data = {}) {
  return Request(`flight/checkLeftSeat`, 'post', undefined, data)
}
/**
 * check是否还有余票 新版
 */
export function kpiFlightCheckLeftSeatAndLowestPrice(data = {}) {
  return Request(`flight/checkLeftSeatAndLowestPrice`, 'post', undefined, data)
}

/**
 * 国内机票因私往返同屏 校验余票
 * */
export function kpiFlightV2CheckRoundTripLeftSeat(data = {}) {
  return Request(`flight/v2/checkRoundTripLeftSeat`, 'post', undefined, data)
}

/**
 * 获取机票预订填写页初始信息
 */
export function kpiFlightGetFlightBookInitInfo(data = {}) {
  return Request(`flight/getFlightBookInitInfo`, 'post', undefined, data)
}
/**
 * 检查是否要填写reason code 旧版
 */
export function kpiFlightCheckReasonCode(data = {}) {
  return Request(`flight/checkReasonCode`, 'post', undefined, data)
}
/**
 * 检查是否要填写reason code 新版
 */
export function kpiFlightCheckVettingAndReasonCode(data = {}) {
  return Request(`flight/checkVettingAndReasonCode`, 'post', undefined, data)
}
/**
 * 校验乘客同时段是否有其他行程 旧版
 */
export function kpiFlightCheckOrderRepeat(data = {}) {
  return Request(`flight/checkOrderRepeat`, 'post', undefined, data)
}
/**
 * 校验乘客同时段是否有其他行程 新版
 */
export function kpiFlightCheckRepeatBooking(data = {}) {
  return Request(`flight/checkRepeatBooking`, 'post', undefined, data)
}
/**
 * 保存机票订单
 */
export function kpiFlightSaveOrder(data = {}) {
  return Request(`flight/saveOrder`, 'post', undefined, data)
}
/**
 * 确认订单
 */
export function kpiFlightConfirmOrder(data = {}) {
  return Request(`flight/confirmOrder`, 'post', undefined, data)
}
/**
 * 获取机票订单列表
 */
export function kpiFlightGetOrderList(data = {}, hiddenLoading = false) {
  return Request(`flight/getOrderList/v3`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 获取机票订单详情
 */
export function kpiFlightgetOrderDetail(data = {}) {
  return Request(`flight/getOrderDetail`, 'post', undefined, data)
}
/**
 * 取消订单
 */
export function kpiFlightCancelOrder(data = {}) {
  return Request(`flight/cancelOrder`, 'post', undefined, data)
}
/**
 * 获取退票页面初始信息
 */
export function kpiFlightGetFlightRefundInitInfo(data = {}) {
  return Request(`flight/v2/getFlightRefundInitInfo`, 'post', undefined, data)
}
/**
 * 退票订单列表
 */
export function kpiFlightGetRefundOrderList(data = {}) {
  return Request(`flight/getRefundOrderList/v2`, 'post', undefined, data)
}
/**
 * 退票下一步校验接口
 */
export function kpiFlightCheckRefundInfo(data = {}) {
  return Request(`flight/checkRefundInfo`, 'post', undefined, data)
}
/**
 * 保存退票订单
 */
export function kpiFlightSaveRefundOrder(data = {}) {
  return Request(`flight/saveRefundOrder`, 'post', undefined, data)
}
/**
 * 获取退票订单详情
 */
export function kpiFlightGetRefundOrderDetail(data = {}) {
  return Request(`flight/v2/getRefundOrderDetail`, 'post', undefined, data)
}
/**
 * 取消退票
 */
export function kpiFlightCancelRefundOrder(data = {}) {
  return Request(`flight/v2/cancelRefundOrder`, 'post', undefined, data)
}

/**
 * 退票订单详情上传附件接口
 */
export function kpiFlightUploadRefundOrderAttachFile(data = {}) {
  return Request(`flight/v2/uploadRefundOrderAttachFile`, 'post', undefined, data)
}

/**
 * 退票订单详情删除附件
 */
export function kpiFlightDeleteRefundOrderAttachFile(data = {}) {
  return Request(`flight/v2/deleteRefundOrderAttachFile`, 'post', undefined, data)
}

/**
 * 获取改签航段选择页面初始信息
 */
export function kpiFlightGetChangeSelectInitInfo(data = {}) {
  return Request(`flight/getChangeSelectInitInfo`, 'post', undefined, data)
}
/**
 * 获取改签航班列表
 */
export function kpiFlightGetFlightChangeList(data = {}) {
  return Request(`flight/getFlightChangeList`, 'post', undefined, data, false, true)
}
/**
 * 获取改签订单填写页初始信息
 */
export function kpiFlightLoadChangingInfo(data = {}) {
  return Request(`flight/loadChangingInfo`, 'post', undefined, data)
}
/**
 * 微信支付后验证是否支付成功
 */
// export function kpiFlightGetOrderPayType(data = {}) {
//   return Request(`flight/GetOrderPayType`, 'post', undefined, data)
// }
/**
 * 获取在线选座初始信息
 */
export function kpiFlightGetChooseSeatMapInitInfo(data = {}) {
  return Request(`flight/getChooseSeatMapInitInfo`, 'post', undefined, data)
}
/**
 * 提交选座信息
 */
export function kpiFlightSubmitChooseSeat(data = {}) {
  return Request(`flight/submitChooseSeat`, 'post', undefined, data)
}
/**
 * 获取国内城市静态数据
 */
export function kpiFlightGetAllCities(data = {}) {
  return Request(`flight/getFlightCityList`, 'post', undefined, data)
}
/**
 * 航司列表
 */
export function kpiFlightGetAllAirLines(data = {}) {
  return Request(`systemInfo/getTravelCardAirLines`, 'post', undefined, undefined)
}
/**
 * 获取酒店会员卡
 */
export function kpiSystemInfoGetHotelGroups(data = {}) {
  return Request(`systemInfo/getHotelGroups`, 'post', undefined, undefined)
}
/**
 * 获取机票差旅标准
 */
export function kpiFlightGetFlightRank(data = {}) {
  return Request(`flight/getFlightRank`, 'post', undefined, data)
}
/**
 * 检查审批 旧版
 */
export function kpiFlightCheckVetting(data = {}) {
  return Request(`flight/checkVetting`, 'post', undefined, data)
}
/**
 * 国内机票一键提交 旧版
 */
export function kpiFlightSubmitOrder(data = {}) {
  return Request(`flight/submitOrder`, 'post', undefined, data)
}
/**
 * 国内机票一键提交 新版
 */
export function kpiFlightSubmitBooking(data = {}) {
  return Request(`flight/submitBooking`, 'post', undefined, data)
}
/**
 * 加载机票预订填写页信息
 */
export function kpiFlightLoadBookingInfo(data = {}) {
  return Request(`flight/loadBookingInfo`, 'post', undefined, data)
}
/**
 * 国内机票下一步校验接口
 */
export function kpiFlightCheckBooking(data = {}) {
  return Request(`flight/checkBooking`, 'post', undefined, data)
}
/**
 * 国内机票因私往返同屏 -- 查询去程航班列表
 */
export function kpiFlightV2SearchRoundTripGoFlights(data = {}) {
  return Request(`flight/v2/searchRoundTripGoFlights`, 'post', undefined, data, false, true)
}
/**
 * 国内机票因私往返同屏 -- 查询返程航班列表
 */
export function kpiFlightV2SearchRoundTripBackFlights(data = {}) {
  return Request(`flight/v2/searchRoundTripBackFlights`, 'post', undefined, data, false, true)
}
/**
 * 国内机票因私往返同屏 -- 筛选和排序航班列表
 */
export function kpiFlightV2FilRoundTripFlights(data = {}) {
  return Request(`flight/v2/filtRoundTripFlights`, 'post', undefined, data, false, true)
}
/**
 * 国内机票因私往返同屏 -- 查询往返程舱位列表
 */
export function kpiFlightV2SearchRoundTripCabins(data = {}, hiddenLoading = false) {
  return Request(`flight/v2/searchRoundTripCabins`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 国内机票 获取保单下载地址
 */
export function kpiFlightGetInsurancePolicyDownloadUrl(data = {}, hiddenLoading = false) {
  return Request(`flight/getInsurancePolicyDownloadUrl`, 'post', undefined, data, false, hiddenLoading, true)
}
/**
 * 国内机票 值机
 */
export function kpiFlightV2CheckInOnline(data = {}, hiddenLoading = false) {
  return Request(`flight/v2/checkInOnline`, 'post', undefined, data, false, hiddenLoading, true)
}
/**
 * 国内机票检查是否存在未使用票提醒接口
 */
export function kpiFlightCheckIsExistsNoUseTicket(data = {}, hiddenLoading = false) {
  return Request(`flight/checkIsExistsNoUseTicket`, 'post', undefined, data)
}
/**
 * 国内机票重新计算价格
 */
export function kpiFlightReCalculateFee(data = {}, hiddenLoading = false) {
  return Request(`flight/ReCalculateFee`, 'post', undefined, data)
}
/**
 * 国内机票审批校验价格是否发生变化
 */
export function kpiFlightCheckOrderPrice(data = {}) {
  return Request(`domesticFlightv2/checkOrderPrice`, 'post', undefined, data)
}

//                                   00             0000000000000
//  0000000000000  00000  0000000    00   000000    0000000000000
//  0000000000000  00000  0000000    00   000000        00 00
//  00         00  00 00           000000 00  00     00000000000
//  00 0000000 00  00 00           000000 00  00     00 00 00 00
//  00 0000000 00  00 0  000000000   00   00  00     00000000000
//  00    00   00  0000  000000000   00   00  00     00000000000
//  00 0000000 00  00 00    00      0000  00  00
//  00    00   00  00 00  0 00 0    00000 00  00     00000000000
//  00    00 0 00  00 00  0 00 00  0000 0 00  00
//  00 0000000000  00000 00 00  0  0 00   00  00    0000000000000
//  00 0000000000  0000  0  00  00   00   00  00    0000000000000
//  00         00  00   00  00   0   00  00   00 0    00  00 00
//  0000000000000  00     0000       00 000   0000   00  000  000
//  0000000000000  00     000        00  0     000  00   00     0
/**
 * 国际航班查询舱位选项
 */
export function kpiIntlflightGet(data = {}) {
  return Request(`intlflight/get`, 'post', undefined, data)
}
/**
 * 国际航班查询舱位选项
 */
export function kpiIntlFlightCanSearch(data = {}) {
  return Request(`intlflight/canSearch`, 'post', undefined, data)
}
/**
 * 搜索国际航班
 */
export function kpiIntlflightList(data = {}) {
  return Request(`intlflight/list`, 'post', undefined, data, false, true)
}
/**
 * 筛选国际航班
 */
export function kpiIntlflightFilt(data = {}) {
  return Request(`intlflight/filt`, 'post', undefined, data)
}
/**
 * 获取国际舱位更多价格
 */
export function kpiIntlflightGetCabinMorePrice(data = {}, hiddenLoading = false) {
  return Request(`intlflight/getCabinMorePrice`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 退改签
 */
export function kpiIntlflightGetIntlFlightCabinRule(data = {}) {
  return Request(`intlflight/getIntlFlightCabinRule`, 'post', undefined, data)
}
/**
 * 获取国际订单填写页初始信息
 */
export function kpiIntlflightGetBookInitInfo(data = {}) {
  return Request(`intlflight/getBookInitInfo`, 'post', undefined, data)
}
/**
 * 检查checkReasonCode
 */
export function kpiIntlflightCheckReasonCode(data = {}) {
  return Request(`intlflight/checkReasonCode`, 'post', undefined, data)
}
/**
 * 保存国际订单
 */
export function kpiIntlflightSaveIntlOrder(data = {}) {
  return Request(`intlflight/saveIntlOrder`, 'post', undefined, data)
}
/**
 * 确认国际订单
 */
export function kpiIntlflightConfirmIntlOrder(data = {}) {
  return Request(`intlflight/saveIntlOrderAndSubmit`, 'post', undefined, data)
}
/**
 * 获取国际机票订单列表
 */
export function kpiIntlflightOrderlist(data = {}) {
  return Request(`intlflight/orderlist/v3`, 'post', undefined, data)
}
/**
 * 获取国际机票订单详情
 */
export function kpiIntlflightOrderdetail(data = {}) {
  return Request(`intlflight/orderdetail`, 'post', undefined, data)
}
/**
 * 国际机票取消订单
 */
export function kpiIntlflightCancelOrder(data = {}) {
  return Request(`intlflight/cancelOrder`, 'post', undefined, data)
}

/**
 * 国际机票校验行程是否重复
 */
export function kpiIntlflightCheckOrderRepeat(data = {}) {
  return Request(`intlflight/checkOrderRepeat`, 'post', undefined, data)
}
/**
 * 获取国际城市静态数据
 */
export function kpiIntlflightGetIntlFlightCityList(data = {}) {
  return Request(`intlflight/getIntlFlightCityList`, 'post', undefined, data)
}
/**
 * 获取国籍
 */
export function kpiIntlflightGetNationList(data = {}) {
  return Request(`intlflight/getNationList`, 'post', undefined, data, false, true)
}
/**
 * 获取国际申请单信息
 */
export function kpiIntlflightGetAuthorizationInfo(data = {}) {
  return Request(`intlflight/getAuthorizationInfo/v3`, 'post', undefined, data)
}
/**
 * 国际机票检查是否存在未使用票提醒接口
 */
export function kpiIntlflightCheckIsExistsNoUseTicket(data = {}) {
  return Request(`intlflight/checkIsExistsNoUseTicket`, 'post', undefined, data)
}
//                        00        00                    00
//  0000000000000         00         00 0000000000  0000000000000
//  0000000000000    000000000000     0 0000000000  0000000000000
//  00         00    000000000000          0 0      00
//  00 0000000 00    00   00   00  00   000000000   00    00
//  00 0000000 00    00   00   00   00  000000000   00    0000000
//  00    00   00    00   00   00    0  00 0 0 00   00    0000000
//  00 0000000 00    00  0000  00       0000 0000   00    00
//  00    00   00    00 00  00 00       000   000   00 000000000
//  00    00 0 00    0000    0 00    0  00     00   00 000000000
//  00 0000000000    000       00    0  000000000   00 00     00
//  00 0000000000    00        00   00  00     00   00 00     00
//  00         00    00        00   0   000000000  00  000000000
//  0000000000000    00      0000  00   000000000  00  000000000
//  0000000000000    00       00    0   00     00   0  00     00
//                                  00                    00
/**
 * 获取酒店查询首页初始信息
 */
export function kpiHotelGetHotelIndexInitInfo(data = {}) {
  return Request(`hotel/getHotelIndexInitInfo`, 'post', undefined, data)
}
/**
 * 获取城市下的酒店筛选条件
 */
export function kpiHotelGetHotelFilterCondition(data = {}) {
  return Request(`hotel/getHotelFilterCondition`, 'post', undefined, data)
}
/**
 * OA 查询酒店-校验行程
 */
export function kpiHotelCanSearch(data = {}) {
  return Request(`hotel/canSearch`, 'post', undefined, data)
}
/**
 * 查询酒店列表-无loading
 */
export function kpiHotelGetHotelList(data = {}) {
  return Request(`hotel/getHotelList/v3`, 'post', undefined, data, false, true)
}
/**
 * 查询酒店列表-有loading
 */
export function kpiHotelGetHotelListLoading(data = {}) {
  return Request(`hotel/getHotelList/v3`, 'post', undefined, data)
}
/**
 * 查询酒店详情
 */
export function kpiHotelGetHotelDetail(data = {}) {
  return Request(`hotel/getHotelDetail/v2`, 'post', undefined, data)
}
/**
 * 获取酒店订单填写初始信息
 */
export function kpiHotelGetBookInitInfo(data = {}) {
  return Request(`hotel/getBookInitInfo`, 'post', undefined, data)
}
/**
 * 酒店重新计算价格
 */
export function kpiHotelReCalculateFee(data = {}) {
  return Request(`hotel/reCalculateFee`, 'post', undefined, data)
}
/**
 * 获取酒店reasoncode
 */
export function kpiHotelCheckVettingAndReasonCode(data = {}) {
  return Request(`hotel/checkVettingAndReasonCode`, 'post', undefined, data)
}
/**
 * 检验国内&国际酒店重复下单
 */
export function kpiHotelCheckOrderRepeat(data = {}) {
  return Request(`hotel/checkOrderRepeat`, 'post', undefined, data)
}
/**
 * 保存酒店订单
 */
export function kpiHotelSaveOrder(data = {}) {
  return Request(`hotel/saveOrder`, 'post', undefined, data)
}
/**
 * 获取酒店订单列表
 */
export function kpiHotelGetOrderList(data = {}, hiddenLoading = false) {
  return Request(`hotel/getOrderList/v3`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 获取酒店订单详情
 */
export function kpiHotelgetOrderDetail(data = {}) {
  return Request(`hotel/getOrderDetail`, 'post', undefined, data)
}
/**
 * 取消订单
 */
export function kpiHotelCancelOrder(data = {}) {
  return Request(`hotel/cancelOrder`, 'post', undefined, data)
}
/**
 * 获取国内酒店城市
 */
export function kpiHotelGetHotelCityList(data = {}) {
  return Request(`hotel/getHotelCityList`, 'post', undefined, data)
}
/**
 * 获取酒店城市模糊检索
 */
export function kpiHotelGetHotelCityByKeyword(data = {}) {
  return Request(`hotel/getHotelCityByKeyword`, 'post', undefined, data)
}
/**
 * 获取酒店差标
 */
export function kpiHotelGetHotelRank(data = {}) {
  return Request(`hotel/getHotelRank`, 'post', undefined, data)
}
/**
 * 国内酒店下单校验接口
 */
export function kpiHotelCheckBooking(data = {}) {
  return Request(`hotel/checkBooking`, 'post', undefined, data)
}

//  0000000000000  00000  0000000    00 0000000000  0000000000000
//  0000000000000  00000  0000000     0 0000000000  0000000000000
//  00         00  00 00                   0 0      00
//  00 0000000 00  00 00           00   000000000   00    00
//  00 0000000 00  00 0  000000000  00  000000000   00    0000000
//  00    00   00  0000  000000000   0  00 0 0 00   00    0000000
//  00 0000000 00  00 00    00          0000 0000   00    00
//  00    00   00  00 00  0 00 0        000   000   00 000000000
//  00    00 0 00  00 00  0 00 00    0  00     00   00 000000000
//  00 0000000000  00000 00 00  0    0  000000000   00 00     00
//  00 0000000000  0000  0  00  00  00  00     00   00 00     00
//  00         00  00   00  00   0  0   000000000  00  000000000
//  0000000000000  00     0000     00   000000000  00  000000000
//  0000000000000  00     000       0   00     00   0  00     00
/**
 * 获取国际酒店查询首页初始信息
 */
export function kpiHotelGetInterHotelIndexInitInfo(data = {}) {
  return Request(`intlhotel/getIntlHotelIndexInitInfo`, 'post', undefined, data)
}
/**
 * 获取城市下的酒店筛选条件
 */
export function kpiHotelGetInterHotelFilterCondition(data = {}) {
  return Request(`intlHotelv2/getHotelFilterList`, 'post', undefined, data)
}
/**
 * OA 查询国际酒店 -- 行程校验
 */
export function kpiInterHotelCanSearch(data = {}) {
  return Request(`intlhotel/canSearch`, 'post', undefined, data)
}
/**
 * 查询国际酒店列表
 */
export function kpiInterHotelGetHotelList(data = {}) {
  return Request(`intlhotel/getIntlHotelList`, 'post', undefined, data, false, true)
}
/**
 * 查询国际酒店详情
 */
export function kpiHotelGetInterHotelDetail(data = {}) {
  return Request(`intlhotel/getIntlHotelDetail`, 'post', undefined, data)
}
/**
 * 获取酒店订单填写初始信息
 */
export function kpiInterHotelGetBookInitInfo(data = {}) {
  return Request(`intlhotel/getIntlBookInitInfo`, 'post', undefined, data)
}
/**
 * 国际酒店获取服务费
 */
export function kpiIntlhotelReCalculateFee(data = {}) {
  return Request(`intlhotel/reCalculateFee`, 'post', undefined, data)
}
/**
 * 国际酒店校验审批
 */
export function kpiInterHotelCheckVettingAndReasonCode(data = {}) {
  return Request(`intlhotel/checkVettingAndReasonCode`, 'post', undefined, data)
}
/**
 * 保存酒店订单
 */
export function kpiInterHotelSaveOrder(data = {}) {
  return Request(`intlhotel/saveOrder`, 'post', undefined, data)
}
/**
 * 获取国际酒店订单列表
 */
export function kpiIntlHotelGetOrderList(data = {}, hiddenLoading = false) {
  return Request(`intlhotel/getIntlOrderList/v2`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 获取国际酒店订单详情
 */
export function kpiIntlHotelgetOrderDetail(data = {}) {
  return Request(`intlhotel/getOrderDetail`, 'post', undefined, data)
}
/**
 * 国际酒店取消订单
 */
export function kpiIntlHotelCancelOrder(data = {}) {
  return Request(`intlhotel/cancelOrder`, 'post', undefined, data)
}
/**
 * 获取国内酒店城市静态数据
 */
export function kpiIntlHotelGetIntlHotelCityList(data = {}) {
  return Request(`intlHotelv2/getCityList`, 'post', undefined, data)
}
/**
 * 获取国际酒店城市、酒店名模糊检索
 */
export function kpiIntlHotelGetIntlHotelCityByKeyword(data = {}) {
  return Request(`intlHotelv2/getHotelSearchEngine`, 'post', undefined, data)
}
/**
 * 获取酒店差标
 */
export function kpiIntlHotelGetIntlHotelRank(data = {}) {
  return Request(`intlhotel/getIntlHotelRank`, 'post', undefined, data)
}
/**
 * 获取酒店申请单信息
 */
export function kpiIntlHotelGetHotelAuthorizationInfo(data = {}) {
  return Request(`intlhotel/getHotelAuthorizationInfo`, 'post', undefined, data)
}
/**
 * 国际酒店下单校验 是否可以下单
 */
export function kpiIntlHotelCheckBooking(data = {}) {
  return Request(`intlhotel/checkBooking`, 'post', undefined, data)
}
//       00              00         0000000000000
//       00              00         0000000000000
//       00         0000000000000       00 00
//    0  00   00    0000000000000    00000000000
//    0  00   00       00            00 00 00 00
//   00  00   0       000 00         00000000000
//   0   00  00       00  00         00000000000
//  00   00  0       00000000000
//      0000         00000000000     00000000000
//      00 0              00
//     00  00             00        0000000000000
//    00    00      0000000000000   0000000000000
//   00      00     0000000000000     00  00 00
// 000        000         00         00  000  000
//  0          00         00        00   00     0
/**
 * 火车票模糊搜索
 */
export function kpiTrainSearchTrainStations(data = {}) {
  return Request(`train/searchTrainStations/v2`, 'post', undefined, data)
}
/**
 * 获取火车票查询首页初始信息
 */
export function kpiTrainGetTrainIndexInitInfo(data = {}, hiddenLoading = false) {
  return Request(`train/getTrainIndexInitInfo`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 火车票出差申请单 查询校验
 */
export function kpiTrainCanSearch(data = {}) {
  return Request(`train/canSearch`, 'post', undefined, data)
}
/**
 * 查询火车票列表
 */
export function kpiTrainGetTrainRouteList(data = {}) {
  return Request(`train/getTrainRouteList`, 'post', undefined, data, false, true)
}
/**
 * 查询火车票列表(筛选)
 */
export function kpiTrainFiltTrainRouteList(data = {}) {
  return Request(`train/filtTrainRouteList`, 'post', undefined, data)
}
/**
 * 查询火车票时刻表
 */
export function kpiTrainGetTrainStopInfo(data = {}) {
  return Request(`train/getTrainStopInfo`, 'post', undefined, data)
}
/**
 * 获取火车票订单填写初始信息
 */
export function kpiTrainGetBookInitInfo(data = {}) {
  return Request(`train/getTrainBookInitInfo`, 'post', undefined, data)
}
/**
 * 检查火车票是否要填写reason code
 */
export function kpiTrainCheckReasonCode(data = {}) {
  return Request(`train/checkVettingAndReasonCode`, 'post', undefined, data)
}
/**
 * 火车票订单提交
 */
export function kpiTrainSaveOrder(data = {}) {
  return Request(`train/saveOrder`, 'post', undefined, data)
}
/**
 * 火车票订单列表
 */
export function kpiTrainOrderList(data = {}, hiddenLoading = false) {
  return Request(`train/getTrainOrderList/v2`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 火车票订单详情
 */
export function kpiTrainOrderDetai(data = {}) {
  return Request(`train/getTrainOrderDetail`, 'post', undefined, data)
}
/**
 * 火车票取消订单 (post 请求按get格式)
 */
export function kpiTrainCancle(data = {}) {
  return Request(`train/cancelOrder`, 'post', undefined, data)
}
/**
 * 火车票获取退票初始信息
 */
export function kpiTrainGetRefundTicketInitInfo(data = {}) {
  return Request(`train/getRefundTicketInitInfo`, 'post', undefined, data)
}
/**
 * 火车票提交退票
 */
export function kpiTrainSubmitRefund(data = {}) {
  return Request(`train/submitRefund`, 'post', undefined, data)
}
/**
 * 火车票获取改签初始信息
 */
export function kpiTrainGetChangeInitInfo(data = {}) {
  return Request(`train/getChangeInitInfo`, 'post', undefined, data)
}
/**
 * 火车票提交改签
 */
export function kpiTrainSubmitChange(data = {}) {
  return Request(`train/submitChange/v2`, 'post', undefined, data)
}
/**
 * 火车票乘客验证
 */
export function kpiTrainVerifyPassenger(data = {}) {
  return Request(`train/verifyPassenger`, 'post', undefined, data)
}
/**
 * 获取火车票城市静态数据
 */
export function kpiTrainGetTrainCityList(data = {}) {
  return Request(`train/getTrainCityList`, 'post', undefined, data)
}
/**
 * 获取火车票差标
 */
export function kpiTrainGetTrainRank(data = {}) {
  return Request(`train/getTrainRank`, 'post', undefined, data)
}
/**
 * 获取火车票差标
 */
export function kpiTrainGetChangeListInfo(data = {}) {
  return Request(`train/getChangeListInitInfo`, 'post', undefined, data)
}

/**
 * 火车票下一步校验接口
 * */
export function kpiTrainCheckBooking(data = {}) {
  return Request(`train/checkBooking`, 'post', undefined, data)
}
/**
 * 火车票 改签职级校验
 * */
export function kpiTrainCheckChangeReasonCode(data = {}) {
  return Request(`train/checkChangeReasonCode/v2`, 'post', undefined, data)
}
/**
 * 火车票--自动登录12306并返回账号
 * */
export function kpiTrainAutoLoginTrainOfficial(data = {}, hiddenLoading = false) {
  return Request(`train/autoLoginTrainOfficial`, 'post', undefined, data, false, hiddenLoading)
}
//    00  00  00     0     00             00
//     00 00 00      00    0000000  00000000000000
//  00000000000000    0    0000000  00000000000000
//  00000000000000 000000 00        00  00      00
//  00          00 00000000    00   00  0000000 00
//  00 00000000 00   00  000000000     00000000
//     00    00      00   00000       0000  00
//     00000000      0000 00 00      00  0000
//     00000000      00 0 00 00 00      00000000
//        00         00 0 00 0000  000000     0000
//    00000000000    00 0 00 00     00 000000000
//    00000000000   00  0 00  00       000000000
//    00  00   00   00  0 000 00       00     00
//    00  00  00   00 000 000  00      000000000
//        00       0  00  00    00     000000000
/**
 * 修改密码
 */
export function kpiUserModifyPassword(data = {}) {
  return Request(`user/modifyPassword`, 'post', undefined, data)
}
/**
 * 个人中心
 */
export function kpiUserGetUserCenterInitInfo(data = {}) {
  return Request(`user/getUserCenterInitInfo`, 'post', undefined, data, true)
}
/**
 * 管理
 */
export function kpiUserGetUserManageInfo(data = {}, hiddenLoading = false) {
  return Request(`user/getUserManageInfo`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 我的获取审批数量
 */
export function kpiUserGetMineCountInfo(data = {}, hiddenLoading = false) {
  return Request(`user/getMineCountInfo`, 'post', undefined, data, false, hiddenLoading)
}

/**
 * 获取预付款明细列表
 */
export function kpiUserGetPrePaymentRecordDetails(data = {}) {
  return Request(`user/getPrePaymentRecordDetails`, 'post', undefined, data)
}
/**
 * 获取常旅客列表-旧
 */
export function kpiUserGetBookPersons(data = {}, hiddenLoading = false) {
  return Request(`user/getBookPersons`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 获取常旅客列表-新
 */
export function kpiFrequentTravelerList(data = {}, hiddenLoading = false) {
  return Request(`frequentTraveler/list`, 'post', undefined, data, false, hiddenLoading)
}
/**
 * 添加常旅客-旧
 */
export function kpiUserAddTraveler(data = {}) {
  return Request(`user/addTraveler`, 'post', undefined, data)
}
/**
 * 添加常旅客-新
 */
export function kpiFrequentTravelerAdd(data = {}) {
  return Request(`frequentTraveler/add`, 'post', undefined, data)
}
/**
 * 导入常旅客-新
 */
export function kpiFrequentTravelerImport(data = {}) {
  return Request(`frequentTraveler/import`, 'post', undefined, data)
}
/**
 * 修改常旅客-旧
 */
export function kpiUserModifyTraveler(data = {}) {
  return Request(`user/modifyTraveler`, 'post', undefined, data)
}
/**
 * 修改常旅客-新
 */
export function kpiFrequentTravelerPut(data = {}) {
  return Request(`frequentTraveler/put`, 'post', undefined, data)
}
/**
 * 移除常旅客-新
 */
export function kpiFrequentTravelerDelete(data = {}) {
  return Request(`frequentTraveler/delete`, 'post', undefined, data)
}
/**
 * 添加成本中心
 */
export function kpiUserAddCostCenter(data = {}) {
  return Request(`user/addCostCenter`, 'post', undefined, data)
}
/**
 * 自定义项列表
 */
export function kpiUserGetCustomItems(data = {}) {
  return Request(`user/getCustomItems`, 'post', undefined, data)
}
/**
 * 添加自定义项
 */
export function kpiUserAddCustomItem(data = {}) {
  return Request(`user/addCustomItem`, 'post', undefined, data)
}
/**
 * 退出
 */
export function kpiUserLogout(data = {}) {
  return Request(`user/logout`, 'post', undefined, data)
}
/**
 * 部门列表
 */
export function kpiUserGetDepartments(data = {}) {
  return Request(`user/getDepartments`, 'post', undefined, data)
}
/**
 * 成本中心
 */
export function kpiUserGetCostCenters(data = {}) {
  return Request(`user/getCostCenters`, 'post', undefined, data)
}
/**
 * BU
 */
export function kpiUserGetBusinessUnits(data = {}) {
  return Request(`user/getBusinessUnits`, 'post', undefined, data)
}
/**
 * 获取忘记密码验证码
 */
export function kpiUserGetForgotPasswordCode(data = {}) {
  return Request(`user/getForgotPasswordCode/v2`, 'post', undefined, data)
}
/**
 * 获取忘记密码验证码 -- 新版
 */
export function kpiUserGetForgotPasswordVerificationCode(data = {}) {
  return Request(`user/getForgotPasswordVerificationCode`, 'post', undefined, data)
}
/**
 * 校验验证码有效性
 */
export function kpiUserCheckVerificationCode(data = {}) {
  return Request(`user/checkVerificationCode`, 'post', undefined, data)
}
/**
 * 忘记密码发送邮箱
 */
export function kpiUserModifyPasswordByEmail(data = {}) {
  return Request(`user/modifyPasswordByEmail/v2`, 'post', undefined, data)
}
/**
 * 忘记密码的重置密码
 */
export function kpiUserResetPassword(data = {}) {
  return Request(`user/resetPassword/v2`, 'post', undefined, data)
}
/**
 * 登录一键绑定
 */
export function kpiUserThreePartyBind(data = {}) {
  return Request(`User/ThreePartyBind`, 'post', undefined, data)
}
/**
 * 临时接口, 获取单位ID
 */
export function kpiUserGetUserDetail(data = {}) {
  return Request(`user/getUserDetail`, 'post', undefined, data)
}
/**
 * 修改手机号 - 发送绑定手机验证码
 * @param mobile
 */
export function kpiUserSendBindMobileValidCode(data = {}) {
  return Request(`user/sendBindMobileValidCode`, 'post', undefined, data)
}
/**
 * 修改手机号 - 绑定手机号码
 */
export function kpiUserBindMobile(data = {}) {
  return Request(`user/bindMobile`, 'post', undefined, data)
}
/**
 * 获取常用联系人列表
 */
export function kpiUserGetDefaultContacts(data = {}) {
  return Request(`user/getDefaultContacts`, 'post', undefined, data)
}
/**
 * 新增常用联系人
 */
export function kpiUserAddDefaultContact(data = {}) {
  return Request(`user/addDefaultContact`, 'post', undefined, data)
}
/**
 * 删除常用联系人
 */
export function kpiUserDeleteDefaultContact(data = {}) {
  return Request(`user/deleteDefaultContact?id=${data.id}`, 'post', undefined, data)
}
/**
 * 设置常用联系人
 */
export function kpiUserSetDefaultContact(data = {}) {
  return Request(`user/setDefaultContact`, 'post', undefined, data)
}
/**
 * 获取用户权限接口
 */
export function kpiUserGetUserPermissions(data = {}) {
  return Request(`user/getUserPermissions`, 'post', undefined, data, true)
}
/**
 * 获取客服页面首页信息
 */
export function kpiUserGetCustomerServiceInitInfo(data = {}) {
  return Request(`user/getCustomerServiceInitInfo`, 'post', undefined, data)
}
/**
 * 机器人问题列表
 */
export function kpiQaList(data = {}) {
  return Request(`qa/list`, 'post', undefined, data)
}
/**
 * 单个机器人问题详情
 */
export function kpiQaGet(data = {}) {
  return Request(`qa/get`, 'post', undefined, data)
}
/**
 * 获取客户配置信息
 */
export function kpiCustomerGetSettings(data = {}) {
  return Request(`customer/getSettingsV2`, 'post', undefined, data, true)
}
/**
 * 获取权限信息
 */
export function kpiUserGetPermissions(data = {}) {
  return Request(`user/getPermissions`, 'post', undefined, data, true)
}
/**
 * 获取关于我们内容
 */
export function kpiUserGetAboutUsContent(data = {}) {
  return Request(`user/getAboutUsContent`, 'post', undefined, data, true)
}
/**
 * 获取快捷常旅客列表
 */
export function kpiFrequentTravelerQuicklist(data = {}) {
  return Request(`frequentTraveler/quicklist`, 'post', undefined, data)
}
/**
 * 获取快捷常旅客列表--新
 */
export function kpiFrequentTravelerIndexQuickList(data = {}) {
  return Request(`frequentTraveler/list`, 'post', undefined, data)
}

//    000000000
//    000000000      000000000000
//    00     00      000000000000
//    00     00           00
//    000000000           00
//                        00
//   00000000000          00
//   00000000000          00
//   00       00          00
//   00  00   00          00
//   00  00   00          00
//   00  00   00          00
//      00  000     00000000000000
//    000     000   00000000000000
//  0000       00

/**
 * 员工列表
 */
export function kpiEmployeeGetEmployees(data = {}) {
  return Request(`employee/getEmployees`, 'post', undefined, data)
}
/**
 * 待审批员工列表 */
export function kpiInviteList(data = {}) {
  return Request(`invite/list`, 'post', undefined, data)
}
/**
 * 待审批员工列表 -- 发起审批接口 */
export function kpiInviteApprove(data = {}) {
  return Request(`invite/approve`, 'post', undefined, data)
}
/**
 * 待审批员工列表 -- 待审批员工数量 */
export function kpiInviteUserCount(data = {}) {
  return Request(`invite/userCount`, 'post', undefined, data)
}
/**
 * 员工初始化数据
 */
export function kpiEmployeeGetEmployeeInitData(data = {}) {
  return Request(`employee/getEmployeeInitData`, 'post', undefined, data)
}
/**
 * 添加员工
 */
export function kpiEmployeeAddEmployee(data = {}) {
  return Request(`employee/addEmployee`, 'post', undefined, data)
}
/**
 * 编辑员工
 */
export function kpiEmployeeEditEmployee(data = {}) {
  return Request(`employee/editEmployee`, 'post', undefined, data)
}
/**
 * 删除员工
 */
export function kpiEmployeeDeleteEmployee(data = {}) {
  return Request(`employee/deleteEmployee`, 'post', undefined, data)
}
/**
 * 查询员工（查看、预订）权限
 */
export function kpiEmployeePermissionQuery(data = {}) {
  return Request(`customer/mobile/permission/query`, 'post', undefined, data)
}

/**
 * 修改员工（查看、预订）权限
 */
export function kpiEmployeePermissionUpdate(data = {}) {
  return Request(`customer/mobile/permission/update`, 'post', undefined, data)
}
/**
 * 获取员工查看预订权限
 */
export function kpiEmployeePermissionSelectStatus(data = {}) {
  return Request(`customer/mobile/permission/selectStatus`, 'post', undefined, data)
}

//      00 00         000000000
//      00 00         000000000      000000000000
//      00 00         00     00      000000000000
// 0000000 0000000    00     00           00
// 0000000 0000000    000000000           00
//      00 00                             00
//  000000 000000    00000000000          00
//  000000 000000    00000000000          00
//      00 00        00       00          00
//      00 00        00  00   00          00
// 0000000 0000000   00  00   00          00
// 0000000 0000000   00  00   00          00
//      00 00           00  000     00000000000000
//      00 00         000     000   00000000000000
//      00 00       0000       00
/**
 * 获取非员工列表
 */
export function kpiNupemployeeGetNupEmployees(data = {}) {
  return Request(`nupemployee/getNupEmployees`, 'post', undefined, data)
}
/**
 * 添加非员工
 */
export function kpiNupemployeeUserAddNupEmployee(data = {}) {
  return Request(`nupemployee/addNupEmployee`, 'post', undefined, data)
}
/**
 * 修改非员工
 */
export function kpiNupemployeeUserEditNupEmployee(data = {}) {
  return Request(`nupemployee/editNupEmployee`, 'post', undefined, data)
}
/**
 * 删除非员工
 */
export function kpiNupemployeeUserDeleteNup(data = {}) {
  return Request(`nupemployee/deleteNup`, 'post', undefined, data)
}

/**
 * 企业微信员工绑定 -- 获取企业微信人员列表
 */
export function kpiWeChatWorkGetUsers(data = {}) {
  return Request(`wechatwork/getUsers`, 'post', undefined, data)
}
/**
 * 企业微信员工绑定 -- 微信人员绑定
 */
export function kpiWeChatWorkBindingUser(data = {}) {
  return Request(`wechatwork/bindingUser`, 'post', undefined, data)
}
/**
 * 企业微信员工绑定 -- 微信人员解绑
 */
export function kpiWeChatWorkUnBindingUser(data = {}) {
  return Request(`wechatwork/unbindingUser`, 'post', undefined, data)
}

/**
 * 钉钉员工绑定 -- 获取钉钉人员列表
 */
export function kpiDingTalkWorkGetUsers(data = {}) {
  return Request(`dingtalkwork/getUsers`, 'post', undefined, data)
}
/**
 * 钉钉员工绑定 -- 钉钉人员绑定
 */
export function kpiDingTalkWorkBindingUser(data = {}) {
  return Request(`dingtalkwork/bindingUser`, 'post', undefined, data)
}
/**
 * 钉钉员工绑定 -- 钉钉人员解绑
 */
export function kpiDingTalkWorkUnBindingUser(data = {}) {
  return Request(`dingtalkwork/unbindingUser`, 'post', undefined, data)
}

/**
 * 飞书员工绑定 -- 获取飞书人员列表
 */
export function kpiFeiShuWorkGetUsers(data = {}) {
  return Request(`feishuwork/getUsers`, 'post', undefined, data)
}
/**
 * 飞书员工绑定 -- 飞书人员绑定
 */
export function kpiFeiShuWorkBindingUser(data = {}) {
  return Request(`feishuwork/bindingUser`, 'post', undefined, data)
}
/**
 * 飞书员工绑定 -- 飞书人员解绑
 */
export function kpiFeiShuWorkUnBindingUser(data = {}) {
  return Request(`feishuwork/unbindingUser`, 'post', undefined, data)
}

//        00              00
//        00          00  00
//   0000000000000    0000000000
//   0000000000000   00000000000
//   00             00    00
//   00                   00
//   00             00000000000000
//   00             00000000000000
//   00
//   00               0000000000
//   00               0000000000
//   00               00      00
//  00                0000000000
// 000                0000000000
//  0                 00      00
/**
 * 获取广告banner列表
 * @param {*adType} 0: 首页   1: 机票   2: 酒店   3:  火车票    4: 国际机票   5:  国际酒店    6: 订单预订成功
 */
export function kpiAdList(data = {}) {
  return Request(`ad/list`, 'post', undefined, data)
}
/**
 * 获取公告列表
 */
export function kpiNoticeList(data = {}) {
  return Request(`notice/list`, 'post', undefined, data)
}
/**
 * 获取公告详情
 */
export function kpiNoticeDetail(data = {}) {
  return Request(`notice/get`, 'post', undefined, data)
}
/***************************************************************************************/
export function kpiUserDeleteNup(data = {}) {
  return Request(`user/deleteNup`, 'post', undefined, data)
}
export function kpiUserGetStaticPageInfo(data = {}) {
  return Request(`user/getStaticPageInfo`, 'post', undefined, data)
}

/**
 * 读取缓存
 */
export function kpiCacheGet(data = {}) {
  return Request(`cache/get`, 'post', undefined, data)
}
/**
 * 设置缓存
 */
export function kpiCacheSet(data = {}) {
  return Request(`cache/set`, 'post', undefined, data)
}
/**
 * 删除缓存
 */
export function kpiCacheRemove(data = {}) {
  return Request(`cache/remove`, 'post', undefined, data)
}

//  00000   0    0
//  0   00  0    0
//  0    0  0    0
//  0    0  0    0
//  0   00  0    0
//  00000   0    0
//  0   00  0    0
//  0    0  0    0
//  0    0  0    0
//  0   00  00  00
//  00000    0000
/**
 * 获取BU列表
 */
export function kpiBuGetBus(data = {}) {
  return Request(`bu/getBus`, 'post', undefined, data)
}
/**
 * 添加BU
 */
export function kpiBuAddBu(data = {}) {
  return Request(`bu/addBu`, 'post', undefined, data)
}
/**
 * 编辑BU
 */
export function kpiBuEditBu(data = {}) {
  return Request(`bu/editBu`, 'post', undefined, data)
}
/**
 * 删除BU
 */
export function kpiBuDeleteBu(data = {}) {
  return Request(`bu/deleteBu`, 'post', undefined, data)
}
//     00               00
//    00                00             00             0000000000
// 00000000000    00000000000000   00  00   00   000000000000000
// 00000000000    00000000000000   00   00  00   00000    00
// 00       00    00          00    00  00 00      00  00000000
// 00       00      0000000000      00     00      00  00000000
// 00000000000      0000000000       0    00       00  00    00
// 00       00          00           00   00       00  00 00 00
// 00       00      00  00            00 00        00  00 00 00
// 00000000000      00  0000000        000         000 00 00 00
// 00       00      00  0000000        000       00000 00 00 00
// 00       00     000  00            00 00      00      000
// 00000000000     0000000          000   000           00  00
// 00000000000    00  0000000000 00000     00000       00    00
// 00       00   00     00000000  00         00       00      00
/**
 * 获取自定义项列表
 */
export function kpiCustomizationGetCustomizations(data = {}) {
  return Request(`customization/getCustomizations`, 'post', undefined, data)
}
/**
 * 添加自定义项
 */
export function kpiCustomizationAddCustomization(data = {}) {
  return Request(`customization/addCustomization`, 'post', undefined, data)
}
/**
 * 编辑自定义项
 */
export function kpiCustomizationEditCustomization(data = {}) {
  return Request(`customization/editCustomization`, 'post', undefined, data)
}
/**
 * 删除自定义项
 */
export function kpiCustomizationDeleteCustomization(data = {}) {
  return Request(`customization/deleteCustomization`, 'post', undefined, data)
}
// 0000000                            00    00
// 00000000000000    00 00000000      00    00           00000000
//  00 00 0000000    00 00000000      00    00     00000 00000000
//  00 00 00   00   00  0 00  00      00000 00     00000 00 00 00
//  00000 00   00  00  00 00 00      000000 00       00  00000000
//  00 00 00   00  00000  00 0000    00  00 0000     00  00 00 00
//  00 00 00   00    00   00   00   000  00 00 00  00000 00 00 00
//  00000 0000000   00    00   00  00 0  00 00  00 00000 00000000
//  00 00 0000000  00000  000  00  0  00 0  00   0   00  00000000
//  00 00          00000  000 00       000  00       00     00
//  000000 00 00         00 0000       00   00       00     00
// 0000000 00  00     00 00  00        000  00       000 00000000
// 000 00 00    00 0000000  0000      00 00        00000    00
//     0000     00 000  0 000  00   000   00000000 00   0000000000
//     00              0  00    00  0       000000      0000000000
/**
 * 获取机票差标列表
 */
export function kpiFlightrankGetFlightRanks(data = {}) {
  return Request(`flightrank/getFlightRanks`, 'post', undefined, data)
}
/**
 * 获取国际机票差标列表
 */
export function kpiIntlFlightRankGetIntlFlightRanks(data = {}) {
  return Request(`intlflightrank/getIntlFlightRanks`, 'post', undefined, data)
}
/**
 * 获取国际机票差标详情
 */
export function kpiIntlFlightRankGetIntlFlightRank(data = {}) {
  return Request(`intlflightrank/getIntlFlightRank`, 'post', undefined, data)
}
/**
 * 获取机票差标详情
 */
export function kpiFlightrankGetFlightRank(data = {}) {
  return Request(`flightrank/getFlightRank`, 'post', undefined, data)
}
/**
 * 获取国内酒店差标列表
 */
export function kpiHotelrankGetHotelRanks(data = {}) {
  return Request(`hotelrank/getHotelRanks`, 'post', undefined, data)
}
/**
 * 获取国内酒店差标详情
 */
export function kpiHotelrankGetHotelRank(data = {}) {
  return Request(`hotelrank/getHotelRank`, 'post', undefined, data)
}
/**
 * 获取城市详情
 */
export function kpiHotelrankGetCityDetail(data = {}) {
  return Request(`hotelrank/getCityDetail`, 'post', undefined, data)
}
/**
 * 获取国际酒店差标列表
 */
export function kpiIntlHotelRankGetIntlHotelRanks(data = {}) {
  return Request(`intlHotelRank/getIntlHotelRanks`, 'post', undefined, data)
}
/**
 * 获取国际酒店差标详情
 */
export function kpiIntlHotelRankGetIntlHotelRank(data = {}) {
  return Request(`intlHotelRank/getIntlHotelRank`, 'post', undefined, data)
}
/**
 * 获取火车票差标列表
 */
export function kpiTrainrankGetTrainRanks(data = {}) {
  return Request(`trainrank/getTrainRanks`, 'post', undefined, data)
}
/**
 * 获取火车票差标详情
 */
export function kpiTrainrankGetTrainRank(data = {}) {
  return Request(`trainrank/getTrainRank`, 'post', undefined, data)
}
/**
 * 获取用车差标列表
 */
export function kpiCarRankGetCarRanks(data = {}) {
  return Request(`carRank/getCarRanksV2`, 'post', undefined, data)
}
/**
 * 获取用车差标详情
 */
export function kpiCarRankGetCarRank(data = {}) {
  return Request(`carRank/getCarRankDetail`, 'post', undefined, data)
}
/**
 * 获取国内机票差旅标准
 */
export function kpiHomsomRankFlight(data = {}) {
  return Request(`homsomRank/flight`, 'post', undefined, data)
}
/**
 * 获取国际机票差旅标准
 */
export function kpiHomsomRankIntlFlight(data = {}) {
  return Request(`homsomRank/intlFlight`, 'post', undefined, data)
}
/**
 * 获取国内酒店差旅标准
 */
export function kpiHomsomRankHotel(data = {}) {
  return Request(`homsomRank/hotel`, 'post', undefined, data)
}
/**
 * 获取国际酒店差旅标准
 */
export function kpiHomsomRankIntlHotel(data = {}) {
  return Request(`homsomRank/intlHotel`, 'post', undefined, data)
}
/**
 * 获取火车票差旅标准
 */
export function kpiHomsomRankTrain(data = {}) {
  return Request(`homsomRank/train`, 'post', undefined, data)
}
/**
 * 获取汽车票差旅标准
 */
export function kpiHomsomRankBus(data = {}) {
  return Request(`homsomRank/bus`, 'post', undefined, data)
}
// 00000                                             000                0
// 0   00                                           0  00               0
// 0    0                                          00   0               0
// 0    0                                          0    0               0
// 0   00   0000    0000    0000    0000   0 000   0        0000    000 0   0000
// 00000   00  00  00  00  00  00  00  00  00  00  0       00  00  00  00  00  00
// 0  0    0    0      00  00      0    0  00   0  0       0    0  0    0  0    0
// 0  00   000000   00000   0000   0    0  0    0  0    0  0    0  0    0  000000
// 0   0   0       0   00      00  0    0  0    0  0    0  0    0  0    0  0
// 0   00  00  00  0   00  00  00  00  00  0    0   0  00  00  00  00  00  00  00
// 0    0   0000    00000   0000    0000   0    0    000    0000    00000   0000
/**
 * 获取ReasonCode列表
 */
export function kpiReasoncodeGetReasonCodes(data = {}) {
  return Request(`reasoncode/getReasonCodes`, 'post', undefined, data)
}
/**
 * 添加ReasonCode
 */
export function kpiReasoncodeAddReasonCode(data = {}) {
  return Request(`reasoncode/addReasonCode`, 'post', undefined, data)
}
/**
 * 编辑ReasonCode
 */
export function kpiReasoncodeEditReasonCode(data = {}) {
  return Request(`reasoncode/editReasonCode`, 'post', undefined, data)
}
/**
 * 删除ReasonCode
 */
export function kpiReasoncodeDeleteReasonCode(data = {}) {
  return Request(`reasoncode/deleteReasonCode`, 'post', undefined, data)
}
//      00             00
//      000000         00
//     0000000        00000000
//    00    00       00000000
//   000000000000   000   00
//  0000000000000  0000000000000
//    00  00   00    00000000000
//    00000000000    00  00   00
//    00000000000    00000000000
//    00  00   00    00000000000
//    00000000000    00
//    00000000000    00
//   00   00   00    00        00
//   00   00  000    000000000000
//   0    00  00      0000000000
/**
 * 获取角色列表
 */
export function kpiRoleGetRoles(data = {}) {
  return Request(`role/getRoles`, 'post', undefined, data)
}
/**
 * 角色详情
 */
export function kpiRoleGetRoleDetail(data = {}) {
  return Request(`role/getRoleDetail`, 'post', undefined, data)
}
/**
 * 获取角色绑定员工数据
 */
export function kpiRoleGetRoleBindUnitPerson(data = {}) {
  return Request(`role/getRoleBindUnitPerson`, 'post', undefined, data)
}
//                  0000000000000        00         0000000000000
//  0000000000000   0000000000000    00  00  00     0000000000000
//  0000000000000       00 00        00  00   00        00 00
//     00   00       00000000000     00  00          00000000000
//     00   00       00 00 00 00    0000000000000    00 00 00 00
//     00   00       00000000000    0000000000000    00000000000
// 000000000000000   00000000000        00           00000000000
// 000000000000000                      00000000
//     00   00       00000000000       000000000     00000000000
//     00   00                        00 0   00
//     00   00      0000000000000    00  00 00      0000000000000
//    00    00      0000000000000   00    000       0000000000000
//    00    00        00  00 00     0    00000        00  00 00
//  000     00       00  000  000     0000   00000   00  000  000
//   0      00      00   00     0     00       000  00   00     0
/**
 * 获取开票信息
 */
export function kpiInvoiceGetInvoices(data = {}) {
  return Request(`invoice/getInvoices`, 'post', undefined, data)
}
//    00             00
//    00    000000    00 00000000
//  0000000 000000    00 00000000
//  0000000 00  00             00
//   0   0  00  00  00         00
//    0 0   00 00   00         00
// 00000000 00 00   00         00
// 00000000 00 00   00         00
//          00  00  00         00
//  0000000 00  00  00         00
//  0000000 00  00  00         00
//  00   00 000000  00         00
//  0000000 00000   00         00
//  0000000 00      00       0000
//  00   00 00      00        00
/**
 * 获取部门列表
 */
export function kpiDepartmentGetDepartments(data = {}) {
  return Request(`department/getDepartments`, 'post', undefined, data)
}
/**
 * 添加部门
 */
export function kpiDepartmentAddDepartment(data = {}) {
  return Request(`department/addDepartment`, 'post', undefined, data)
}
/**
 * 编辑部门
 */
export function kpiDepartmentEditDepartment(data = {}) {
  return Request(`department/editDepartment`, 'post', undefined, data)
}
/**
 * 删除部门
 */
export function kpiDepartmentDeleteDepartment(data = {}) {
  return Request(`department/deleteDepartment`, 'post', undefined, data)
}
/**
 * 获取部门详情列表
 */
export function kpiDepartmentGetDepartmentDetailList(data = {}) {
  return Request(`department/getDepartmentDetailList`, 'post', undefined, data)
}
//         00 00          00              00
//         00 00          00              00             00
//   000000000000         00              00              00
//   000000000000   00000000000000   000000000000          0
//   00    00       00000000000000   000000000000
//   00    00            000         00   00   00       00    0
//   00000 00 00        00000        00   00   00    00 00    00
//   00000 00 00       0000000       00   00   00    00 00     00
//   00 00 00 00       00 00 00      000000000000   00  00      00
//   00 00  000       00  00  00     000000000000   00  00      00
//   00 00  00       000000000000    00   00   00   0   00    0
//  00 000 000  00 000 00000000 00        00            00    00
//  00    00 00 00  0     00              00            00000000
// 00    00   0000        00              00             000000
//  0    0     00         00              00
/**
 * 获取成本中心列表
 */
export function kpiCostcenterGetCostCenters(data = {}) {
  return Request(`costcenter/getCostCenters`, 'post', undefined, data)
}
/**
 * 添加成本中心
 */
export function kpiCostcenterAddCostCenter(data = {}) {
  return Request(`costcenter/addCostCenter`, 'post', undefined, data)
}
/**
 * 编辑成本中心
 */
export function kpiCostcenterEditCostCenter(data = {}) {
  return Request(`costcenter/editCostCenter`, 'post', undefined, data)
}
/**
 * 删除成本中心
 */
export function kpiCostcenterDeleteCostCenter(data = {}) {
  return Request(`costcenter/deleteCostCenter`, 'post', undefined, data)
}
/**
 * 获取成本中心详情列表
 */
export function kpiCostcenterGetCostCenterDetailList(data = {}) {
  return Request(`costcenter/getCostCenterDetailList`, 'post', undefined, data)
}
//     0   0       0   00       00   0          0         0    0         0
//        00      00            00   00         0        0000000000    0000000
//   000000000    0  0000000     00000     00000000000   0             0000000
//   0   0   0    0              0   0         000       0  000000     0     0
//   000000000   00  0   00      0   0        0 00      00             0000000
//   0   0   0    0   0  0    00000000000     0 0 0      0  000000     0     0
//   000000000    0   0  0       0   00      0  0 00     0
//       0        0   0  0     00  0  00    00  0  00    0  000000      0 0   0
//  00000000000   0      0    0  00000  0  00 00000 00   0  0    0   0  0     00
//       0        0 00000000       0       0    0        0  000000   0  0    0 0
//       0        0            000000000        0        0  0    0      00000
/**
 * 上传单位logo
 */
export function kpiCompanybaseinfoUploadCompanyLogo(data = {}) {
  return POSTFILE(`companybaseinfo/uploadCompanyLogo`, data)
}
//      00          00             00  00000000  0000000 0000000
//      00          00  0000000    00  00000000  0000000 0000000
// 00   00   00    00   0000000    00  00    00     0 0  00   00
// 00   00   00   00             00000 00000000     0 0  0000000
// 00   00   00  00  0           00000 00    00   000000 00   00
// 000000000000     00 000000000   00  00000000   00 0 0 0000000
// 000000000000    00  000000000   00             00 0 0    00
//      00        000      00      000 000000000  00 0 0 00 00
//      00       0000      00    00000 000000000  000 00 0000000
// 00   00   00  0 00      00    0000     00      00   000000000
// 00   00   00    00      00      00  00 00000   00000000  00
// 00   00   00    00      00      00  00 00000   00   0  000000
// 000000000000    00      00      00  00000      000000    00
// 000000000000    00     000     000 00 0000000  000000 0000000
//           00    00     00      00 00    00000  00   0 0000000
/**
 * 获取机票出行提示
 */
export function kpiTravelTipsFlight(data = {}) {
  return Request(`travelTips/flight/v2`, 'post', undefined, data)
}
/**
 * 获取国际机票出行提示
 */
export function kpiTravelTipsIntlFlight(data = {}) {
  return Request(`travelTips/intlFlight`, 'post', undefined, data)
}
/**
 * 获取酒店出行提示
 */
export function kpiTravelTipsHotel(data = {}) {
  return Request(`travelTips/hotel`, 'post', undefined, data)
}
/**
 * 获取火车出行提示
 */
export function kpiTravelTipsTrain(data = {}) {
  return Request(`travelTips/train/v2 `, 'post', undefined, data)
}
/**
 * 用车板块
 */
/* 用车订单列表 */
export function kpiCarOrderList(data = {}) {
  return Request(`car/Order/List`, 'post', undefined, data)
}
/* 用车订单详情 */
export function kpiCarOrderDetail(data = {}) {
  return Request(`car/Order/OrderDetail`, 'post', undefined, data)
}
/* 用车取消订单 */
export function kpiCarOrderCancel(data = {}) {
  return Request(`car/Order/OrderCancel`, 'post', undefined, data)
}

/* 获取总积分 */
export function kpiQueryuserintinfodetail(data = {}) {
  return Request(`integralapi/front/integral/queryuserintinfodetail`, 'post', undefined, data)
}

/* 获取积分商品 */
export function kpiFrontProductProductpage(data = {}) {
  return Request(`integralapi/front/product/productpage`, 'post', undefined, data)
}

/* 获取商品详情 */
export function kpiFrontProductProductdetail(data = {}) {
  return Request(`integralapi/front/product/productdetail`, 'post', undefined, data)
}

/* 查询积分记录 */
export function kpiFrontProductQueryrecord(data = {}) {
  return Request(`integralapi/front/integral/queryrecord`, 'post', undefined, data)
}

/* 查询订单列表 */
export function kpiFrontOrderQueryPage(data = {}) {
  return Request(`integralapi/front/order/querypage`, 'post', undefined, data)
}

/* 积分创建订单 */
export function kpiFrontOrderCreateOrder(data = {}) {
  return Request(`integralapi/front/order/createorder`, 'post', undefined, data)
}

/* 积分创建订单 */
export function kpiFrontOrderDetail(data = {}) {
  return Request(`integralapi/front/order/detail`, 'post', undefined, data)
}
/**
 * 12306 登录
 * */
/** 12306登录接口 */
export function kpiTrainLoginOfficial(data = {}) {
  return Request(`train/loginOfficial`, 'post', undefined, data)
}
/** 绑定12306账户 */
export function kpiTrainBindOfficialUser(data = {}) {
  return Request(`train/bindOfficialUser`, 'post', undefined, data)
}
/** 移除12306账户 */
export function kpiTrainDeleteOfficialUser(data = {}) {
  return Request(`train/deleteOfficialUser`, 'post', undefined, data)
}
/** 验证码核验 12306账户 */
export function kpiTrainValidateOfficialUser(data = {}) {
  return Request(`train/validateOfficialUser`, 'post', undefined, data)
}
/** 证件号后四位核验 12306账户 */
export function kpiTrainGetValidateByIdNoLastFourCaptcha(data = {}) {
  return Request(`train/getValidateByIdNoLastFourCaptcha`, 'post', undefined, data)
}
/** 获取 12306账户 列表 */
export function kpiTrainGetOfficialUsers(data = {}) {
  return Request(`train/getOfficialUsers`, 'post', undefined, data)
}
/** 火车票人脸识别接口 */
export function kpiTrainFaceValidateOfficialUser(data = {}) {
  return Request(`train/faceValidateOfficialUser`, 'post', undefined, data)
}
/** 火车票12306找回密码初始化 */
export function kpiTrainGetFindPasswordInitInfo(data = {}) {
  return Request(`train/getFindPasswordInitInfo`, 'post', undefined, data)
}
/** 火车票12306找回密码--获取验证码 */
export function kpiTrainGetFindPasswordCaptcha(data = {}) {
  return Request(`train/getFindPasswordCaptcha`, 'post', undefined, data)
}
/** 火车票12306找回密码--提交 */
export function kpiTrainConfirmFindPassword(data = {}) {
  return Request(`train/confirmFindPassword`, 'post', undefined, data)
}

/** 火车票改签 获取审批留信息 */
export function kpiTrainGetChaAppInitInfo(data = {}) {
  return Request(`train/getChangeApprovalInitInfo/v2`, 'post', undefined, data)
}

/** 获取 主题 列表 */
export function kpiThemeCurrent(data = {}) {
  return Request(`theme/current`, 'post', undefined, data, true)
}

/** 获取 差旅目的 */
export function kpiUserGetPurposes(data = {}) {
  return Request(`user/getPurposes`, 'post', undefined, data)
}

/** 投诉与建议 提交 */
export function kpiComplaintAndSuggestionSubmit(data = {}) {
  return Request(`complaintAndSuggestion/submit`, 'post', undefined, data)
}

/** 支付初始化接口 */
export function kpiPayPayInit(data = {}) {
  return Request(`Pay/PayInit`, 'post', undefined, data)
}

/** 杉德支付获取数据接口 */
export function kpiPaySandPayStart(data = {}) {
  return Request(`Pay/SandPayStart`, 'post', undefined, data)
}

/** 发起收银台支付 */
export function kpiPayHomsompaystart(data = {}) {
  return Request(`Pay/homsompaystart`, 'post', undefined, data)
}

/**
 * 获取授权码配置接口
 */
export function kpiOaGetAutCodeSetting(data = {}) {
  return Request(`oa/getAuthorizationCodeSettings`, 'post', undefined, data)
}

/**
 * 获取邀请二维码
 */
export function kpiInviteGetInviteInfo(data = {}) {
  return Request(`invite/getinviteinfo`, 'post', undefined, data)
}

/**
 * home页初始化信息接口
 */
export function kpiGetUnImportantInitInfo(data = {}) {
  return Request(`home/getUnImportantInitInfo`, 'post', undefined, data)
}


/** home 页获取审批信息 */
export function kpiHomeGetToDoList(data = {}) {
  return Request(`home/getToDoList`, 'post', undefined, data, true)
}


/**
 * 设置页获取币种列表
 */
export function kpiGetCurrencyInfos(data = {}) {
  return Request(`systemInfo/getCurrencyInfos`, 'post', undefined, data)
}

/**
 * 获取订单可选扩展字段
 */
export function kpiUserGetOrderExtendFields(data = {}) {
  return Request(`user/getOrderExtendFields`, 'post', undefined, data)
}

/**
 * 获取员工扩展字段可选项
 */
export function kpiUserGetEmployeeExtendFields(data = {}) {
  return Request(`user/getEmployeeExtendFields`, 'post', undefined, data)
}

/**
 * 获取手机国家代码
 */
export function kpiSystemInfoGetMobileCountryCodes(data = {}) {
  return Request(`systemInfo/getMobileCountryCodes`, 'post', undefined, data)
}

/**
 * 模糊检索手机国家代码
 */
export function kpiSystemInfoGetMobileCountryCodesByKeyword(data = {}) {
  return Request(`systemInfo/getMobileCountryCodesByKeyword`, 'post', undefined, data)
}

/**
 * 上传文件接口
 */
export function kpiSystemInfoUploadFiles(data = {}) {
  return Request(`systemInfo/uploadFiles`, 'post', undefined, data)
}

/**
 * 旅行豆接口
 */
export function kpiLxdPersonalAcct(data = {}) {
  return Request(`lvxingdouapi/openapi/lxd/personal/acct`, 'post', undefined, data)
}

/**
 * 旅行豆 规则
 */
export function kpiLxdOrderRuleUrl(data = {}) {
  return Request(`lvxingdouapi/openapi/lxd/order/ruleUrl`, 'post', undefined, data)
}

/**
 * 旅行豆 预估奖励
 */
export function kpiLxdAwardPredict(data = {}) {
  return Request(`lvxingdouapi/openapi/award/predict`, 'post', undefined, data)
}

/**
 * 日志收集
 */
export function kpiSystemCollect(data = {}) {
  let personal = store.state.PersonalInfor || {}
  let d = [{
    ...data,
    U: personal && personal.UserInfo && personal.UserInfo.UserID || '',
    C: personal && personal.CompanyCode || '',
    DT: dayjs().format(), // 当地时间
  }]
  return Request('collect', 'post', undefined, d, true, true, true)
}

/**
 * 获取高德地图 key
 */
export function kpiGetAMapKey(data = {}) {
  return Request('home/getUnionInfo', 'post', undefined, data, true, true)
}
